<template>
    <tr
        @click="handleRowClick"
        class="border-b cursor-pointer hover:bg-gray-50"
    >
        <td
            class="whitespace-nowrap text-left py-2 align-middle"
            :class="tdClasses"
        >
            <div class="flex gap-2 items-center">
                <file-delete-button 
                    :file="file"
                    :disabled="disabled"
                    :class="deleteButtonClasses"
                    @fileDeleted="$emit('fileDeleted')"
                />
                <action-button
                    title="Open file"
                    @click.stop="openFile"
                    icon="eye"
                    :class="viewButtonClasses"
                />
                <action-button
                    title="Edit file"
                    @click.stop="handleEditClick"
                    :disabled="isEditDisabled"
                    icon="pencil"
                    v-if="extraColumns && isAdmin"
                    :class="viewButtonClasses"
                />
                <action-button
                    title="Rejected"
                    v-if="!isAdmin && fileIsRejected"
                    @click.stop="showRejectionReason"
                    iconStyle="duotone"
                    class="text-yellow-700"
                    icon="exclamation-triangle"
                    :class="viewButtonClasses"
                />
            </div>
        </td>
        <td
            class="whitespace-nowrap text-left py-2 truncate max-w-[150px]"
            :title="file.comment"
            :class="filenameTdClasses"
        >
            <!-- file type icon -->
            <file-icon
                :class="deleteButtonClasses"
                class="cursor-pointer text-gray-500 mr-1"
                :showThumbnail="showThumbnail"
                :type="type"
                :url="file.file"
                :size="1"
            ></file-icon>

            <!-- high priority -->
            <i v-if="file.high_priority" class="fas fa-triangle-exclamation text-xs cursor-pointer text-red-500 mr-1"></i>


            <!-- comment bubble -->
            <i v-if="file.comment" class="fa-regular fa-comment text-xs cursor-pointer text-gray-500 mr-1 relative top-[1px]"></i>
            
            <!-- file name -->
            {{ file.file }}
        </td>
        <td
            v-if="extraColumns"
            class="whitespace-nowrap text-left py-2 w-3/12"
            :class="tdClasses"
        >
            <span>{{ uploadType }}</span>
        </td>
        <td
            v-if="showCategory"
            class="whitespace-nowrap text-left py-2 px-3"
        >
            <span>{{ category }}</span>
        </td>
        <td class="whitespace-nowrap text-left py-2" :class="tdClasses">
            {{ file.user?.full_name }}
        </td>
        <td class="whitespace-nowrap text-left py-2" :class="tdClasses">
            {{ fileCreationDate }}
        </td>
        <td
            v-if="extraColumns"
            class="whitespace-nowrap text-left py-2"
            :class="tdClasses"
        >
            <span v-if="file.status">{{ statusText }}</span>
            <span v-else></span>
        </td>
        <td
            v-if="extraColumns && !hideInternalAndExpDate"
            class="whitespace-nowrap text-left py-2"
            :class="tdClasses"
        >
            <span v-if="expirationDate">{{ expirationDate }}</span>
            <span v-else></span>
        </td>
        <td
            v-if="showPageNumber"
            class="whitespace-nowrap text-left py-2"
            :class="tdClasses"
        >
            <span v-if="file.page">{{ file.page }}</span>
            <span v-else></span>
        </td>
        <td
            v-if="extraColumns && isAdmin && !hideInternalAndExpDate"
            class="whitespace-nowrap text-center py-2"
            :class="tdClasses"
        >
            <i
                v-if="file.internal_only"
                class="fas pointer-events-none fa-check text-green-600"
                :class="``"
                aria-hidden="true"
            ></i>
        </td>
    </tr>
    <base-modal
        titleRole="warning"
        icon="exclamation-triangle"
        :open="rejectionModalOpen"
        @close="rejectionModalOpen = false"
        title="Rejected file"
        class="min-w-[40%]"
    >
        {{ file.rejection_reason }}
    </base-modal>
</template>

<script>
import dateService from "@/services/utils/dateFormatterService";
import FileDeleteButton from "./FileDeleteButton.vue";
import startCase from "lodash/startCase";

export default {
    components: {
        FileDeleteButton,
    },
    data() {
        return {
            selectedStatus: this.file.status?.id,
            selectedType: this.file.uploadType?.id,
            internalOnly: this.file.internal_only,
            rejectionModalOpen: false,
        };
    },
    inject: {
        applicationAlias: {
            default: null,
        }, // No default value. No way to make it required
    },
    emits: ["fileDeleted", "editFile"],
    computed: {
        application() {
            return this.$store.getters["applications/current"];
        },
        applicationIsLocked() {
            return this.$store.getters["applications/currentIsLocked"];
        },
        applicationIsClosed() {
            return this.$store.getters["applications/currentIsClosed"];
        },
        statusText() {
            return startCase(this.file.status?.text);
        },
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
        category() {
            return this.$store.getters["unassignedUploads/categories"].find(cat => cat.value === this.file.category)?.text;
        },
        isEditDisabled() {
            return this.applicationIsClosed;
        },
        type() {
            return this.file.file.split(".").pop();
        },
        fileCreationDate() {
            return dateService.humanReadable(this.file.created_at);
        },
        expirationDate() {
            return dateService.humanReadable(this.file.expires_at);
        },
        computedApplicationAlias() {
            return (
                this.applicationAlias ??
                this.$store.getters["applications/current"]?.alias
            );
        },
        fileUrl() {
            return this.computedApplicationAlias
                ? `funding/application/${this.computedApplicationAlias}/uploads/${this.file.file}`
                : `funding/unassigned/uploads/${this.file.file}`;
        },
        deleteButtonClasses() {
            return this.extraColumns ? "text-xxs" : "";
        },
        viewButtonClasses() {
            return this.extraColumns ? "text-xxs" : "";
        },
        statuses() {
            return this.$store.getters.statuses;
        },
        fileIsRejected() {
            return (
                this.file.status_id ===
                Object.values(this.statuses).find(
                    (status) => status.name === "REJECTED"
                ).id
            );
        },
        uploadTypes() {
            return this.$store.getters.uploadTypes ?? [];
        },
        uploadType() {
            return this.$store.getters.uploadTypeText(this.file);
        },
        filenameTdClasses() {
            const classes = [...this.tdClasses];
            if (this.extraColumns) {
                classes.push("w-6/12");
            } else {
                classes.push("w-11/12");
            }
            return classes;
        },
        tdClasses() {
            const classes = [];
            if (this.extraColumns) {
                classes.push("px-1");
            } else {
                classes.push("px-4");
            }
            return classes;
        },
    },
    props: {
        hideInternalAndExpDate: {
            type: Boolean,
            default: true,
        },
        file: {
            type: Object,
            required: true,
        },
        showThumbnail: {
            type: Boolean,
            required: false,
            default: false,
        },
        extraColumns: {
            type: Boolean,
            required: false,
            default: false,
        },
        showCategory: {
            type: Boolean,
            required: false,
            default: false,
        },
        showPageNumber: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        openFile() {
            this.$http.get(this.fileUrl, {
                openInNewTab: true,
            });
        },
        handleEditClick() {
            this.$emit("editFile", this.file.id);
        },
        handleTypeInput(event) {
            const modelValue = event.target.value;
            this.$http
                .patch(this.fileUrl, {
                    data_upload_type_id: modelValue,
                })
                .then((response) => {
                    this.$store.dispatch("applications/setCurrentApplication", {
                        application: response.data,
                        refreshApplications: true,
                    });
                });
        },
        handleStatusInput(event) {
            const modelValue = event.target.value;
            this.$http
                .patch(this.fileUrl, {
                    status_id: modelValue,
                })
                .then((response) => {
                    this.$store.dispatch("applications/setCurrentApplication", {
                        application: response.data,
                        refreshApplications: true,
                    });
                });
        },
        handleInternalOnlyInput(event) {
            const modelValue = event.target.checked ? 1 : 0;
            this.$http
                .patch(this.fileUrl, {
                    internal_only: modelValue,
                })
                .then((response) => {
                    this.$store.dispatch("applications/setCurrentApplication", {
                        application: response.data,
                        refreshApplications: true,
                    });
                });
        },
        handleRowClick() {
            this.openFile();
        },
        showRejectionReason() {
            this.rejectionModalOpen = true;
        },
    },
};
</script>

<style scoped>
.fas::before {
    vertical-align: middle;
}
</style>

// create our app
import { createApp } from "vue";
import app from "./App.vue";
const App = createApp(app);

// import vuex
import store from "./store";
App.use(store);

// import router
import router from "./router";
App.use(router);

// unwrap injected refs
App.config.unwrapInjectedRef = true;

// import drag scroll
import VueDragscroll from "vue-dragscroll";
App.use(VueDragscroll);

// import highlight js
// todo: load this on component level without breaking tests
import "highlight.js/styles/tomorrow-night-blue.css";
import "highlight.js/lib/common";
import hljsVuePlugin from "@highlightjs/vue-plugin";
App.use(hljsVuePlugin);

// import middleware pipeline and global middleware
import MiddlewarePlugin from "vue-router-middleware-plugin";
import globalMiddleware from "./router/middleware/globalMiddlewareRegistration";
import middlewareIncludes from "./services/middlewareFormatterService";
App.use(MiddlewarePlugin, {
    router,
    context: { store, middlewareIncludes },
    middleware: globalMiddleware,
});

// other imports
import "./registerServiceWorker";
import "./css/tailwind.css";
import "./assets/tailwind.css";

// register global components
import globalComponents from "./components/global/register";
Object.entries(globalComponents).forEach(([key, value]) =>
    App.component(key, value)
);

// import axios and set our $http global prop
import httpService from "./services/httpService";
const http = httpService({
    router,
    store,
});
store.$http = http;
App.config.globalProperties.$http = http;
App.provide("$http", App.config.globalProperties.$http);

App.config.globalProperties.$landingSiteUrl = process.env.VUE_APP_LANDING_SITE_URL;

// local fontawesome ready to go should we choose it
// // import fontawesome
// import { library as faLibrary } from "@fortawesome/fontawesome-svg-core";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// // import specific icons
// import { faCircleCheck as fadCircleCheck } from "@fortawesome/pro-duotone-svg-icons";
// import { faSpinnerThird as fadSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
// import { faCircleEllipsis as fadCircleEllipsis } from "@fortawesome/pro-duotone-svg-icons";
// import { faCircleXmark as fadCircleXmark } from "@fortawesome/pro-duotone-svg-icons";
// import { faCircleDashed as farCircleDashed } from "@fortawesome/pro-regular-svg-icons";
// // import { fad } from "@fortawesome/pro-duotone-svg-icons";
// // import { far } from "@fortawesome/pro-regular-svg-icons";
// // add icons to the faLibrary
// faLibrary.add(
//     fadCircleCheck,
//     fadSpinnerThird,
//     fadCircleEllipsis,
//     fadCircleXmark,
//     farCircleDashed
// );
// // faLibrary.add(fad, far);
// // register fontawesome
// App.component("font-awesome-icon", FontAwesomeIcon);


// Custom directives
App.directive('focus', {
    mounted: (el, binding) => {

        // Check for value first since it's the less performance impactful task
        if (!binding.value && typeof(binding.value) !== 'undefined') return;

        // If we have the stop modifier, we don't want to override 
        // the focus of any children that may already be focused
        if (binding.modifiers?.stop && el.contains(document.activeElement)) return;
        el.focus();
    }
})


// mount our app

App.mount("#app");
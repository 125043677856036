import router from "@/router/index";
import isObject from "@/services/utils/isObject.js";

//import { startsWith } from "lodash";

// manages our user state
export default {
    state() {
        return {
            accessToken: null,
            refreshToken: null,
            isRefreshingToken: false,
            pendingRequests: [],
            user: null,
            loadingUser: false,
            loginValues: null,
            devIds: [
                39407, // Hans
                102133, // Jeremy
            ],
        };
    },

    mutations: {
        // set our token
        setAccessToken(state, accessToken) {
            state.accessToken = accessToken;
        },

        setRefreshToken(state, refreshToken) {
            state.refreshToken = refreshToken;
        },

        setIsRefreshingToken(state, isRefreshingToken){
            state.isRefreshingToken = isRefreshingToken;
        },

        setPendingRequests(state, pendingRequests){
            state.pendingRequests = pendingRequests;
        },

        // set our user object
        setUser(state, user) {
            state.user = user;
        },

        // set our user object's primary phone
        setPrimaryPhoneNumber(state, phoneNumber) {
            console.log("setting primary phone:", phoneNumber);
            let user = state.user;
            if(user){
                user.primary_phone = phoneNumber;
            }
        },

        setUserShares(state, shares) {
            state.user.shares = shares;
        },

        // set our primary email
        setPrimaryEmail(state, email) {
            if (!state.user) {
                return;
            }
            state.user.email = email;
        },

        setAsha(state, asha) {
            state.user.asha_number = asha;
        },

        // set if we are loading our user
        setLoadingUser(state, bool = true) {
            state.loadingUser = bool;
        },

        // log out the user and redirect to login
        logout(state) {
            state.user = null;
            state.accessToken = null;
            state.refreshToken = null;
        },

        // set our login values object (used with validation redirect)
        setLoginValues(state, values) {
            state.loginValues = values;
        },
    },

    actions: {
        /**
         * Log in a user
         *
         * @param {Context} context
         * @param {string} accessToken
         * @param {string} refreshToken
         */
        async login({ dispatch, rootGetters, getters }, { tokens, redirect, params = null }) {
            // commit our login
            const accessToken = tokens.accessToken;
            const refreshToken = tokens.refreshToken;        
            dispatch("setToken", { accessToken, refreshToken });

            // load our user data
            await dispatch("loadUser");
            if  (rootGetters["inbox/INBOX_ENABLED"]) {
                dispatch("inbox/initialize");
            }
            if (getters.isAdmin) {
                dispatch("getAllDevices");
            }

            //dispatch("loadData", null, { root: true });

            // set our redirect
            if (redirect) {
                const to = { name: redirect };

                if (params) {
                    to.params = JSON.parse(params);
                }

                router.push(to);
            }
        },

        /**
         * Set our JWT access token and refresh token
         *
         * @param {Context} context
         * @param {string} accessToken
         * @param {string} refreshToken
         */
        setToken({ commit },{ accessToken, refreshToken}) {
            commit("setAccessToken", accessToken);
            commit("setRefreshToken", refreshToken);
            
            // set tokens into localstorage
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);

        },

        /**
         * Load user data from the API
         *
         * @param {Context} context
         */
        async loadUser({ commit, dispatch }) {
            commit("setLoadingUser", true);
            let response;

            try {
                response = await this.$http.get("funding/self", {
                    notifications: false,
                });
            } catch (error) {
                //dispatch('notifications/create', { type: 'error', message: 'please log in' });
                commit("setLoadingUser", false);
                dispatch("logout");
                return;
            }

            commit("setLoadingUser", false);

            // commit our login
            dispatch("setUser", response?.data);
        },

        /**
         * Update our user model
         *
         * @param {Context} context
         * @param {object} user
         */
        setUser({ commit }, user) {
            commit("setUser", user);
        },

        /**
         * Update our user model's primary phone number
         *
         * @param {Context} context
         * @param {string} phoneNumber
         */
        setPrimaryPhoneNumber({ commit }, phoneNumber) {
            commit("setPrimaryPhoneNumber", phoneNumber);
        },

        /**
         * Update our user's primary email
         *
         * @param {Context} context
         * @param {object} email
         */
        setPrimaryEmail({ commit }, email) {
            commit("setPrimaryEmail", email);
        },

        /**
         * Update our user's asha number
         *
         * @param {Context} context
         * @param {String} asha
         */
        setAsha({ commit }, asha) {
            commit("setAsha", asha);
        },

        /**
         * Log out our user
         *
         * @param {Context} context
         * @param {string} redirect optional query parameter that allows a user to be returned
         * to a given route name after they log back in
         */
        logout({ commit, dispatch }, redirect = null) {
            commit("logout");
            dispatch('setPrivacyMode', false, {root: true});

            // remove user info from localStorage
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("_idleTime");

            // reset the Vuex state
            this.reset({
                self: false,
                nested: true,
            });

            // add redirect parameter if needed
            let params = {};
            if (typeof redirect === "string") {
                params.redirect = redirect;
            }

            //handling redirect route parameters
            if (isObject(redirect)) {
                params = redirect;
            }

            if (redirect !== false) {
                router.push({ name: "login", query: params });
            }
        },

        /**
         * Check if we have an access token in local storage
         *
         * @param {Context} context
         */
        async checkForLocalUser({ dispatch }) {
            const accessToken = localStorage.getItem("accessToken");
            const refreshToken = localStorage.getItem("refreshToken");
            let urlParams = new URLSearchParams(window.location.search);

            if (refreshToken) {
                if(urlParams.get('redirect')){
                    await dispatch("login", { 
                        'tokens':{ accessToken, refreshToken},
                        'redirect': urlParams.get('redirect'),
                        'params': urlParams.get('params'),
                    });
                }else{
                    //no redirect param
                    await dispatch("login", {'tokens':{ accessToken, refreshToken}});
                }
            }
        },

        /**
         * Set our login values
         *
         * @param {Context} context
         */
        setLoginValues({ commit }, values) {
            commit("setLoginValues", values);
        },
    },

    getters: {
        accessToken(state) {
            return state.accessToken;
        },

        refreshToken(state) {
            return state.refreshToken;
        },

        isRefreshingToken(state){
            return state.isRefreshingToken;
        },

        pendingRequests(state){
            return state.pendingRequests;
        },

        user(state) {
            return state.user;
        },

        hasAdminRole(state) {
            return (name) => {
                return state.user?.admins?.some(admin => admin.code === name);
            }
        },

        isConsultant(state) {
            return state.user?.admins?.some(admin => admin.code === "funding_admin")
                && !state.user?.admins?.some(admin => admin.code === "funding_superadmin")
                && !state.user?.admins?.some(admin => admin.code === "funding_owner_admin");
        },

        isAdmin(state) {
            return state.user?.admins?.some(
                (admin) =>
                    admin.code === "funding_admin" 
                    || admin.code === "funding_superadmin"
                    || admin.code === "funding_owner_admin"
                    || admin.code === "funding_trial_admin"
            );
        },

        isSuperAdmin(state) {
            return state.user?.admins?.some(
                (admin) => 
                    admin.code === "funding_superadmin"
                    || admin.code === "funding_owner_admin"
                    || admin.code === "funding_trial_admin"
            );
        },

        isOwnerAdmin(state) {
            return state.user?.admins?.some(
                (admin) => admin.code === "funding_owner_admin"
            );
        },

        loggedIn(state) {
            return !!state.refreshToken;
        },

        // are we a dev?
        isDev(_state) {
            return process.env.VUE_APP_NODE_ENV === "box";
        },

        loadingUser(state) {
            return state.loadingUser;
        },

        loginValues(state) {
            return state.loginValues;
        },
    },
};

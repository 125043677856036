<template>
    <div class="w-full overflow-hidden md:overflow-visible">
        <div
            class="relative flex flex-col md:flex-row justify-center w-full px-2.5 sm:px-5 md:px-0 min-h-screen"
        >
            <div
                class="relative min-w-0 pb-8 sm:px-4 md:px-8 w-full flex justify-center"
            >
                <div class="w-full" :class="{ 'max-w-4xl': !tableLayout }">
                    <!-- title -->
                    <div class="w-full flex flex-col justify-end my-4">
                        <h6>Welcome, {{ $store.getters.user?.first_name }}</h6>
                        <div class="flex items-center justify-between flex-wrap gap-2">
                            <h2 class="leading-normal -ml-0.5">My Applications</h2>
                            
                            <!-- external new application button -->
                            <new-application-button 
                                v-if="!nothingToShowExternal && !isAdmin"
                                class="w-full sm:w-fit"
                                @confirmed="goToApplication" 
                            />
                        </div>
                    </div>

                    <!-- if we had a error loading applications show this -->
                    <div v-if="loadApplicationsError" class="">
                        <response-errors
                            :errors="loadApplicationsError"
                            message="There was a problem loading your applications. Please contact the funding staff if it persists."
                        />
                    </div>

                    <!-- if we are still loading our applications show this -->
                    <h3
                        v-else-if="applications === null"
                        class="text-center py-4 lg:py-16 text-gray-600"
                    >
                        Loading Applications...
                    </h3>

                    <!-- if we have loaded our applications, but don't have any show this, except when the lack of applications comes from an empty search result -->
                    <div
                        v-else-if="nothingToShowExternal"
                        class="text-center py-4 lg:py-16"
                    >

                        <!-- external new applications button when no applications exist -->
                        <new-application-button 
                            class="px-3 py-1 ml-2"
                            @confirmed="goToApplication" 
                        />
                    </div>

                    <!-- if we aren't loading, haven't had an error, and have applications to show, then show them -->
                    <template v-else>

                        <div class="pb-2.5 mb-2.5">

                            <!-- filters & new application -->
                            <div
                                class="flex flex-grow items-center justify-between flex-wrap lg:flex-nowrap w-full gap-4"
                            >
                                <!-- filters -->
                                <div class="order-1 flex gap-3 items-center justify-start w-full lg:w-fit">
                                    
                                    <!-- search -->
                                    <div class="flex gap-2 items-center justify-start lg:w-fit pr-2">
                                        <input
                                            class="h-7 grow lg:w-[290px] rounded border py-px px-2.5 border-gray-300 text-sm"
                                            placeholder="search"
                                            v-model="search"
                                            @keyup.enter="startSearch"
                                            @input="handleSearchInput"
                                        />
                                        <primary-button
                                            size="small"
                                            @click="startSearch"
                                            :disabled="!searchEnabled"
                                        >
                                            Search
                                        </primary-button>
                                    </div>

                                    <template v-if="!isAdmin">
                                        <!-- status select -->
                                        <dropdown-selector
                                            v-if="!isAdmin"
                                            name="STATUSES"
                                            :items="filtered_statuses"
                                            searchValue="text"
                                            itemValue="id"
                                            itemText="text"
                                            :selection="stages"
                                            @onSelectAll="stages = filtered_statuses.map((x) => x.id)"
                                            @onDeselectAll="stages = []"
                                            @onSelectionChange="stages = $event"
                                        >
                                            <template #more-info>
                                                <status-definition-modal />
                                            </template>
                                        </dropdown-selector>

                                        <!-- sort -->
                                        <select
                                            v-model="sort" 
                                            class="external-sort-select text-xxs bg-transparent border text-slate-500 border-slate-500 uppercase w-fit px-2 font-medium shadow-sm py-[6px] rounded-md pr-6"
                                        >   
                                            <option value="created_at">Created (oldest)</option>
                                            <option value="-created_at">Created (newest)</option>
                                            <option value="updated_at">Updated (oldest)</option>
                                            <option value="-updated_at">Updated (newest)</option>
                                            <option value="name">Client Name ↑</option>
                                            <option value="-name">Client Name ↓</option>
                                        </select>

                                        <!-- clear filters -->
                                        <button 
                                            class="cursor-pointer text-xs disabled:opacity-50 text-slate-500 w-fit"
                                            @click="clearFilters"
                                        >
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                            Clear Filters
                                        </button>
                                    </template>
                                </div>

                                <!-- new application button -->
                                <div v-if="isAdmin" class="order-0 lg:order-2 w-full lg:w-fit">
                                    <!-- internal new application button -->
                                    <new-application-button 
                                        class="w-full lg:w-fit "
                                        @confirmed="goToApplication" 
                                    />
                                </div>
                            </div>

                            <!-- search option toggles -->
                            <div class="flex items-center mt-4 flex-wrap w-full gap-4">
                                <application-search-toggle
                                    v-for="toggle in searchToggles"
                                    :key="toggle.value"
                                    :value="toggle.value"
                                    :text="toggle.text"
                                />
                            </div>
                        </div>

                        <!-- table view -->
                        <application-table v-if="isAdmin"/>

                        <!-- list view -->
                        <application-list v-else/>
                    </template>
                </div>
            </div>

            <toggle-sidebar class="hidden xm:block">
                <dashboard-resources />
                <task-list v-if="isAdmin" />
            </toggle-sidebar>
        </div>
    </div>
</template>

<script>
import ApplicationList from "@/components/dashboard/ApplicationList.vue";
import ApplicationTable from "@/components/dashboard/ApplicationTable.vue";
import DashboardResources from "@/components/dashboard/DashboardResources.vue";
import TaskList from "@/components/task/TaskList.vue";
import ApplicationSearchToggle from "@/components/dashboard/ApplicationSearchToggle.vue";
import NewApplicationButton from "@/components/dashboard/NewApplicationButton.vue";
import DropdownSelector from "@/components/dashboard/DropdownSelector.vue";
import StatusDefinitionModal from "@/components/dashboard/StatusDefinitionModal.vue";
//import NotesList from "@/components/notes/NotesList.vue";

export default {
    components: {
        ApplicationList,
        ApplicationTable,
        TaskList,
        NewApplicationButton,
        DropdownSelector,
        //NotesList,
        DashboardResources,
        ApplicationSearchToggle,
        StatusDefinitionModal,
    },
    data() {
        return {
            loadApplicationsError: null,
            sidebarHidden: false,
            rightSideBarClosed: false,
            leftSideBarClosed: false,
            searchEnabled: false,
        };
    },
    mounted() {
        if (this.applications === null) {
            this.$store
                .dispatch("applications/loadAll")
                .catch((errors) => (this.loadApplicationsError = errors));

            //this.$store.dispatch("applications/loadAll");
        }

    },
    unmounted() {
        return this.$store.commit("applications/clearAllApplications");
    },
    computed: {
        hasActiveFilters(){
            return this.$store.getters["applications/hasActiveFilters"];
        },
        nothingToShowExternal(){
            return !this.applications?.length && !this.isAdmin && !this.hasActiveFilters;
        },
        tableLayout() {
            return this.isAdmin;
        },
        applications() {
            return this.$store.getters["applications/all"];
        },
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
        isSuperAdmin() {
            return this.$store.getters.isSuperAdmin;
        },
        filtered_statuses() {
            return this.$store.getters["applications/statuses"];
        },
        searchToggles() {
            if( this.isAdmin ) {
                return [
                    { value: "alias", text: "Alias" },
                    { value: "contributor", text: "Contributor Info" },
                    { value: "insurance", text: "Insurance Name" },
                    { value: "client", text: "Client Info" },
                    { value: "slp", text: "SLP Info" },
                    { value: "qad", text: "QAD Customer ID" },
                    { value: "sales_order", text: "QAD Sales Order Number" },
                    { value: "rma", text: "RMA Number" },
                    { value: "brightree", text: "Brightree Patient ID" },
                    { value: "ca_number", text: "Call Authorization (CA) #" },
                ]
            } else {
                return [
                    { value: "alias", text: "Alias (FA#)" },
                    { value: "client", text: "Client Info" }
                ]
            }
        },
        search_options: {
            get() {
                return this.$store.getters["applications/search_options"];
            },
            set(value) {
                this.$store.commit("applications/setSearchOptions", value);
            },
        },
        search: {
            get() {
                return this.$store.getters["applications/search"];
            },
            set(value) {
                this.$store.commit("applications/setSearch", value);
            },
        },
        sort: {
            get() {
                return this.$store.getters["applications/sort"];
            },
            set(value) {
                this.$store.commit("applications/setSort", value);
                this.$store.dispatch("applications/loadAll");
            },
        },
        stages: {
            get() {
                return this.$store.getters["applications/stages"];
            },
            set(value) {
                this.$store.commit("applications/setStages", value);
                this.$store.dispatch("applications/loadAll");
            },
        },
        searchLengthRequirmentMet() {
            return (this.search.length === 0 || this.search.length > 2);
        },
    },
    methods: {
        clearFilters() {
            this.$store.commit("applications/clearFilters");
            this.$store.dispatch("applications/loadAll");
        },
        goToApplication() {
            this.$store.dispatch(
                "createApplication/setStartingNewApplication",
                true
            );
            this.$router.push({ name: "application.create" });
        },
        startSearch() {
            if( this.searchEnabled ) {
                this.$store.dispatch("applications/loadAll");
            }
        },
        handleSearchInput() {
            this.searchEnabled = this.searchLengthRequirmentMet;
        }
    },
};
</script>

<style scoped>
    .external-sort-select {
        /** for the dropdown indicator */
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='rgb(100, 116, 139)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 5px center;
        background-size: 2em;
    }
</style>

<template>
    <li class="text-ellipsis overflow-hidden">
        <span v-if="title" class="font-bold mr-1 inline-block">{{title}}: </span>
        <a v-if="link" :href="link" target="_blank" >
            {{ value }}
        </a>
        <span v-else v-html="value" class="inline-block"></span>
    </li>
</template>

<script>
export default {
    name: "SummaryCardItem",
    props: {
        title: [String, Number],
        value: [String, Number],
        link: {type:String, required:false}
    },
}
</script>

<style scoped>
a{
    color:#0891B2;
    font-weight: bold;
    text-decoration: underline;
}
</style>
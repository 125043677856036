import router from "@/router/index";

import { Store } from "vuex";
import { createStore } from "vuex-extensions";
import createCache from "vuex-cache";

// shared mutations
import createMutationsSharer from "vuex-shared-mutations";
const sharedMutations = [
    "account/setEmails", 
    "setPrimaryEmail",
    "invites/setHideModal",
    "setUserShares",
    "setAllApplications",
    "setPrivacyMode",
    "setPrivacyException",
];

// vuex local storage persistance
import VuexPersistence from "vuex-persist";
const modulesToPersist = ["createApplication", "invites"];

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    modules: modulesToPersist,
});

// modules
import user from "./userStore";
import account from "./accountStore";
import deviceUUID from "./deviceUUIDStore";
import applications from "./applicationsStore";
import applicationSummary from "./applicationSummaryStore";
import createApplication from "./createApplicationStore";
import spinner from "./spinnerStore";
import http from "./httpStore";
import notifications from "./notificationsStore";
import clientInfoCompletion from "./clientInfoCompletionStore";
import evaluationCompletion from "./evaluationCompletionStore";
import tandcCompletion from "./tandcCompletionStore";
import equipmentSelection from "./equipmentSelectionStore";
import repairEvaluationCompletion from "./repairEvaluationCompletionStore";
import slpAddendumCompletion from "./slpAddendumCompletionStore";
import insuranceCompletion from "./insuranceCompletionStore";
import status from "./statusStore";
import region from "./regionStore";
import role from "./rolesStore"; 
import deviceType from "./deviceTypesStore";
import uploadType from "./uploadTypesStore";
import historyFeed from "./historyFeedStore";
import admin from "./adminStore";
import applicationPriority from "./applicationPrioritiesStore";
import tasks from "./tasksStore";
import notes from "./notesStore";
import invites from "./invitesStore";
import inbox from "./inboxStore";
import unassignedUploads from "./unassignedUploadsStore";

export default createStore(Store, {
    modules: {
        spinner,
        http,
        notifications,
        user,
        account,
        applications,
        createApplication,
        applicationSummary,
        deviceUUID,
        deviceType,
        uploadType,
        status,
        region,
        clientInfoCompletion,
        evaluationCompletion,
        tandcCompletion,
        equipmentSelection,
        repairEvaluationCompletion,
        slpAddendumCompletion,
        insuranceCompletion,
        historyFeed,
        admin,
        applicationPriority,
        tasks,
        invites,
        notes,
        inbox,
        role,
        unassignedUploads,
    },
    state: {
        primaryNotice: {
            title: `Year-End Processing Reminder`,
            message: `
                If you need your purchase device to be shipped by the end of the year for 
                insurance purposes, please submit your COMPLETED funding applications to 
                PRC-Saltillo by 10/15/24. While we will make every effort to process in time, 
                it does not guarantee fulfillment of services. Questions? Contact your 
                PRC-Saltillo AAC Consultant.
            `,
            showOnLogin: true,
            showWhenCreatingApplication: false,
        },
        fundingDataLoaded: null,
        fundingDataFailed: null,
        privacyMode: false,
        privacyException: null,
        statuses: {},
        roles: {},
        uploadTypes: {},
        deviceTypes: {},
        applicationPriorities: {},
        additionalRequirementTypes: [],
        regions: [],
        icons: {
            delete: {
                icon: "trash-can",
                style: "light",
            }, 
        },
        taskTypes: []
    },
    mutations: {
        setPrivacyMode(state, value) {
            state.privacyMode = value;
        },
        setPrivacyException(state, value) {
            state.privacyException = value;
        },
        setFundingDataLoaded(state) {
            state.fundingDataLoaded = true;
        },
        setFundingDataFailed(state) {
            state.fundingDataFailed = true;
        },
        setStatuses(state, statuses) {
            state.statuses = statuses;
        },
        setRoles(state, roles) {
            state.roles = roles;
        },
        setUploadTypes(state, uploadTypes) {
            state.uploadTypes = uploadTypes;
        },
        setDeviceTypes(state, deviceTypes) {
            state.deviceTypes = deviceTypes;
        },
        setApplicationPriorities(state, applicationPriorities) {
            state.applicationPriorities = applicationPriorities;
        },
        setAdditionalRequirementTypes(state, additionalRequirementTypes) {
            state.additionalRequirementTypes = additionalRequirementTypes;
        },
        setRegions(state, regions) {
            state.regions = regions;
        },
        setTaskTypes( state, taskTypes ) {
            state.taskTypes = taskTypes;
        },
    },
    actions: {
        /**
         * Load basic funding data
         *
         * @param {Context} context
         */
        async loadData({ commit, dispatch }) {
            let response;

            try{
                response = await this.$http.get("/funding/funding-data", {
                    spinner: false,
                    successNotification: false,
                    errorNotification: "Failed to load funding data",
                });
            } catch {
                commit("setFundingDataFailed");
                return;
            }

            dispatch("setFundingData", response?.data);
        },

        setFundingData({ commit }, data) {
            if(!data){
                return commit("setFundingDataFailed");
            }

            // commit funding data
            // !!! IMPORTANT: all funding data must be stored on the root state here
            // in the index, or else it will be lost when a user logs out, and won't
            // be reloaded when they log back in (unless they reload the page) !!!
            commit("setStatuses", data?.statuses);
            commit("setRoles", data?.roles);
            commit("setDeviceTypes", data?.device_types);
            commit("setUploadTypes", data?.upload_types);
            commit("setRegions", data?.regions);
            commit("setAdditionalRequirementTypes", data?.additional_requirement_types);
            commit("setApplicationPriorities", data?.application_priorities);
            commit("setTaskTypes", data?.task_types);

            // mark our funding data as loaded
            commit("setFundingDataLoaded");
        },

        /**
         * Display a not-authorized notification and redirect to dash
         */
        notAuthorized({ dispatch }, options = {}) {
            dispatch("notifications/create", {
                type: "error",
                message: "Not authorized for this content",
            });

            // if we set our application option redirect to that application's overview
            if (options.application) {
                return router.push({
                    name: "application.overview",
                    params: { alias: options.application },
                });
            }

            // otherwise redirect to the dashboard
            router.push({ name: "dashboard" });
        },

        togglePrivacyMode({ getters, dispatch }){
            dispatch('setPrivacyMode', !getters.privacyMode);
        },

        setPrivacyMode({ commit, dispatch }, value){
            commit('setPrivacyMode', value);

            // if we are turning off privacy mode, then clear any exception
            if(!value){
                dispatch('setPrivacyException', null);
            }
        },

        setPrivacyException({ commit }, value){
            commit('setPrivacyException', value);
        },
        setServiceWorker(_, worker){
            window.serviceWorker = worker;
        },
        setServiceWorkerUpdated({ dispatch }, value){
            if(value === true){
                const note = {type: 'info', message: 'An update is available, please refresh your browser.',persist: 'superPersist',}; 
                dispatch('notifications/create', note);
            }
        },
        checkServiceWorkerUpdated(){
            window.serviceWorker?.update();
        },
    },
    getters: {
        primaryNotice(state) {
            return state.primaryNotice;
        },
        privacyMode(state) {
            return state.privacyMode;
        },
        privacyException(state) {
            return state.privacyException;
        },
        getAdditionalRequirementTypes(state) {
            return state.additionalRequirementTypes;
        },
        regions(state) {
            return state.regions;
        },
        icons(state) {
            return state.icons;
        },
        fundingDataLoaded(state) {
            return state.fundingDataLoaded;
        },
        fundingDataFailed(state) {
            return state.fundingDataFailed;
        },
    },
    plugins: [
        createCache(),
        createMutationsSharer({ predicate: sharedMutations }),
        vuexLocal.plugin,
    ],
});

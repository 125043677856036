<template>
    <div class="p-3 border rounded">
        <div class="border-b pb-1 mb-2">
            <span class="font-bold summary-card__title" v-html="cardTitle"></span>
            <span v-if="icon"
                class="fad ml-2" :class="icon">
            </span>
        </div>
        <ul class="text-sm">
            <summary-card-item v-for="item in filteredItems" 
                         :key="item.name"
                         :title="item.title"
                         :value="item.value"
                         :link="item.link"
            />
        </ul>
    </div>
</template>

<script>
import SummaryCardItem from "@/components/global/SummaryCardItem";

export default {
    name: "SummaryCard",

    components: { SummaryCardItem },

    props: {
        cardTitle: {
            type: String,
        },
        items: {
            type: Array,
            validator: (items) => {
                return items.every( (item) => {
                    return Object.hasOwn( item,'title' ) && Object.hasOwn(item, 'value')
                })
            }
        },
        icon: {
            type: String,
            default: null
        },
        filterNull: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        filteredItems() {
            if(!this.filterNull){
                return this.items;
            }

            return this.items.filter(item => item.value !== null);
        }
    },

    methods: {
    },
}
</script>

<style scoped>
.summary-card__title, li {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
</style>
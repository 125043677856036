<template>
    <tr
        :class="computedClasses"
    >
        <td class="whitespace-nowrap text-left py-2 px-4">
            <router-link
                :to="{
                    name: 'application.overview',
                    params: { alias: this.application.alias },
                }"
            >
                <primary-button size="small">View</primary-button>
            </router-link>
            <primary-button
                size="small"
                class="ml-2"
                :disabled="show_closed"
                @click="onCreateTaskClick(application)"
            >
                Create Task
            </primary-button>
        </td>
        <td class="whitespace-nowrap text-left py-2 px-4">
            {{ application.alias }}
        </td>
        <td class="whitespace-nowrap text-left py-2 px-4">
            {{ application.first_name + " " + application.last_name }}
        </td>
        <td class="whitespace-nowrap text-left py-2 px-4">
            {{
                application.addresses && application.addresses.length != 0
                    ? application.addresses[0].state
                    : ""
            }}
        </td>
        <td class="whitespace-nowrap text-left py-2 px-4">
            {{ dob }}
        </td>
        <td class="whitespace-nowrap text-left py-2 px-4">
            {{ application.priority ? application.priority.text : "N/A" }}
        </td>
        <td class="whitespace-nowrap text-left py-2 px-4">
            {{ statusText }}
        </td>
        <td class="whitespace-nowrap text-left py-2 px-4">
            {{ application.type }}
        </td>
        <td class="whitespace-nowrap text-left py-2 px-4">
            {{ started }}
        </td>
        <td class="whitespace-nowrap text-left py-2 px-4">
            {{ updated }}
        </td>
    </tr>
</template>

<script>
import dateService from "@/services/utils/dateFormatterService";

export default {
    props: {
        application: {
            type: Object,
            required: true,
        },
        privacyMode: {
            type: Boolean,
            default: false,
        },
        privacyException: {
            type: Object,
        },
    },
    methods: {
        onCreateTaskClick(application) {
            let taskData = {
                createMode: true,
                selectedTask: {
                    alias: application.alias,
                },
            };
            this.$store.dispatch("tasks/openTaskModal", taskData);
        },
    },

    computed: {
        computedClasses(){
            const classes = [];

            if(this.application.onbase_order_id !== null){
                classes.push('bg-slate-200');  
            } else {
                classes.push('border-b');
            }

            if(this.shouldHide){
                classes.push('blur');
                classes.push('pointer-events-none');
            }

            return classes;
        },

        shouldHide(){
            const exceptionMatches = this.privacyException?.firstName?.toLowerCase() === this.application.first_name?.toLowerCase() 
                                  && this.privacyException?.lastName?.toLowerCase()  === this.application.last_name?.toLowerCase();

            return this.privacyMode && !exceptionMatches;
        },

        dob() {
            return dateService.humanReadable(this.application.dob);
        },

        started() {
            return dateService.humanReadable(this.application.created_at);
        },

        show_closed() {
            return this.$store.getters["applications/show_closed"];
        },

        updated() {
            return dateService.humanReadable(this.application.updated_at);
        },

        statusText() {
            return this.$store.getters.statuses[this.application.status_id]
                ?.text;
        },
    },
};
</script>

<style scoped></style>

import ApplicationAdminRoutes from "@/router/routes/applicationAdminRoutes.js";
import ClientInfoRoutes from "@/router/routes/clientInfoRoutes.js";
import InsuranceInfoRoutes from "@/router/routes/insuranceInfoRoutes.js";
import CmnRoutes from "@/router/routes/cmnRoutes.js";
import EquipmentSelectionRoutes from "@/router/routes/equipmentSelectionRoutes.js";
import TandcRoutes from "@/router/routes/tandcRoutes.js";
import TrialEquipmentSelectionRoutes from "@/router/routes/trialEquipmentSelectionRoutes.js";
import EvaluationRoutes from "@/router/routes/evaluationRoutes.js";
import RepairEvaluationRoutes from "@/router/routes/repairEvaluationRoutes.js";
import SLPAddendumRoutes from "@/router/routes/slpAddendumRoutes.js";
import AdditionalRequirementRoutes from "@/router/routes/additionalRequirementRoutes.js";

export default [
    {
        path: ":alias",
        name: "application.overview",
        component: () => import("@/views/application/Overview.vue"),
    },
    {
        path: ":alias/admin",
        component: () => import("@/views/application/admin/Admin.vue"),
        children: [...ApplicationAdminRoutes],
    },
    {
        path: ":alias/submit",
        component: () => import("@/views/application/SectionIndex.vue"),
        children: [{
            path: "",
            name: "application.submit",
            component: () => import("@/views/application/Submit.vue"),
        }],
    },
    {
        path: ":alias/client-info",
        component: () => import("@/views/application/SectionIndex.vue"),
        children: [...ClientInfoRoutes],
        meta: {
            infoSection: "client_info",
        },
    },
    {
        path: ":alias/insurance-info",
        component: () => import("@/views/application/SectionIndex.vue"),
        children: [...InsuranceInfoRoutes],
        meta: {
            infoSection: "insurance_info",
        },
    },
    {
        path: ":alias/cmn",
        component: () => import("@/views/application/SectionIndex.vue"),
        children: [...CmnRoutes],
        meta: {
            infoSection: "cmn_info",
        },
    },
    {
        path: ":alias/equipment-selection",
        component: () => import("@/views/application/SectionIndex.vue"),
        children: [...EquipmentSelectionRoutes],
        meta: {
            infoSection: "equipment_selection_info",
        },
    },
    {
        path: ":alias/uploads",
        component: () => import("@/views/application/SectionIndex.vue"),
        children: [
            {
                path: "",
                name: "application.uploads",
                component: () => import("@/views/application/uploads/uploads.vue"),
            },
        ],
    },
    {
        path: ":alias/terms-and-conditions",
        component: () => import("@/views/application/SectionIndex.vue"),
        children: [...TandcRoutes],
        meta: {
            infoSection: "trial_terms_and_conditions_info",
        },
    },
    {
        path: ":alias/trial-equipment-selection",
        component: () => import("@/views/application/SectionIndex.vue"),
        children: [...TrialEquipmentSelectionRoutes],
        meta: {
            infoSection: "trial_equipment_selection_info",
        },
    },
    {
        path: ":alias/evaluation",
        component: () => import("@/views/application/SectionIndex.vue"),
        children: [...EvaluationRoutes],
        meta: {
            infoSection: "evaluation_info",
        },
    },
    {
        path: ":alias/repair-evaluation",
        component: () => import("@/views/application/SectionIndex.vue"),
        children: [...RepairEvaluationRoutes],
        meta: {
            infoSection: "repair_evaluation_info",
        },
    },
    {
        path: ":alias/slp-addendum",
        component: () => import("@/views/application/SectionIndex.vue"),
        children: [...SLPAddendumRoutes],
        meta: {
            infoSection: "slp_addendum_info",
        },
    },
    {
        path: ":alias/additional-requirement",
        component: () => import("@/views/application/SectionIndex.vue"),
        children: [...AdditionalRequirementRoutes],
        meta: {
            infoSection: "additional_completion_requirement_info",
        },
    },
];

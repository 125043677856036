//import router from "@/router/index";

// manages our user state
export default {
    state() {
        return {};
    },

    mutations: {
    },

    actions: {
    },

    getters: {
        statuses(_, __, rootState){
            return rootState.statuses;
        },
        getStatusId(_, __, rootState){
            return (name) => {
                return Object.values(rootState.statuses).find(status => status.name === name)?.id ?? 0;
            }
        },
        getStatusName(_, __, rootState){
            return (id) => {
                return rootState.statuses[id]?.name;
            }
        },
        getStatusIdFromCompletionArray(_, getters)
        {
            let statuses = {
                "REJECTED" : getters.getStatusId("REJECTED"),
                "COMPLETED" : getters.getStatusId("COMPLETED"),
                "IN_PROGRESS" : getters.getStatusId("IN_PROGRESS"),
                "NOT_STARTED" : getters.getStatusId("NOT_STARTED"),
            };

            return (completionArray) => {
                // if any items are rejected return REJECTED
                if(completionArray.includes(statuses.REJECTED)){
                    return statuses.REJECTED;
                }

                // if all of our items are COMPLETED return COMPLETED
                if(completionArray.every(status => status === statuses.COMPLETED)){
                    return statuses.COMPLETED;
                }

                // if all of our items are null or NOT_STARTED return NOT_STARTED
                if(completionArray.every(status => status === statuses.NOT_STARTED || status === null)){
                    return statuses.NOT_STARTED;
                }

                // If we are neither all COMPLETE, nor all NOT_STARTED/null then assume we are in progress
                // obviously this will have to get a bit smarter once we start working with more status types
                return statuses.IN_PROGRESS;
            }
            
        },
        getStatusBackgroundColor(_, getters){
            return (id) => {
                let statusName = getters.statuses[id]?.name;
            
                switch (statusName) {
                    case "COMPLETED":
                        return "bg-status_COMPLETED-500";
                    case "IN_PROGRESS":
                        return "bg-status_IN_PROGRESS-500";
                    case "UNDER_REVIEW":
                        return "bg-status_UNDER_REVIEW-500";
                    case "REJECTED":
                        return "bg-status_REJECTED-500";
                    default:
                        return "bg-status_NOT_STARTED-500";
                }
            }
        }
    },   
};

<template>
    <transition name="fade">
        <div
            class="grid gap-4"
            v-if="schema.hide !== true"
            v-bind="schema.attributes"
            :class="schema.class"
            v-bind:[dataName]="`${schema.name}--group`"
        >
            <div
                v-if="schema.label"
                class="text-xl font-bold group__label order-1"
                :class="schema.labelClass"
                v-html="schema.label"
                v-bind:[dataName]="`${schema.name}--label`"
            ></div>

            <!-- teleport target -->
            <form-teleport v-if="schema.teleport" class="order-2" :teleport="schema.teleport"></form-teleport>

            <!-- description -->
            <div
                v-if="schema.description"
                class="form-label__description text-gray-500 mb-2 order-3 col-span-full"
                v-html="schema.description"
                :class="schema.descriptionClass"
                v-bind:[dataName]="`${schema.name}--description`"
            ></div>

            <!-- fields -->
            <template v-for="field in schema.fields" :key="field.name">
                <!-- recursively call more field-groups if this is what we are passed -->
                <form-group
                    v-if="field.type === 'group'"
                    :schema="field"
                    :form="form"
                    :disabled="disabled"
                    class="order-5"
                    @submitForm="$emit('submitForm')" 
                />

                <form-teleport-field
                    v-else-if="field.type === 'teleport'"
                    :field="field"
                    class="order-5"
                />

                <!-- otherwise generate the input -->
                <component
                    v-else
                    :is="fieldComponent(field)"
                    class="order-5"
                    :modelValue="form.data[field.name]"
                    :field="field"
                    :form="form"
                    :disabled="disabled"
                    @submitForm="$emit('submitForm')" 
                />
            </template>
        </div>
    </transition>
</template>

<script>
import FormInput from "./FormInput.vue";
import FormSelect from "./FormSelect.vue";
import FormTextarea from "./FormTextarea.vue";
import FormHidden from "./FormHidden.vue";
import FormRadio from "./FormRadio.vue";
import FormCounts from "./FormCounts.vue";
import FormCheckbox from "./FormCheckbox.vue";
import FormCheckboxes from "./FormCheckboxes.vue";
import FormFile from "./FormFile.vue";
import FormTeleport from "./FormTeleport.vue";
import FormRichtext from "./FormRichtext.vue";
import FormRadios from "./FormRadios.vue";
import FormTeleportField from "./FormTeleportField.vue";
import FormElement from "./FormElement";
import FormAddress from "./FormAddress";

export default {
    name: "form-group",
    props: ["schema", "form", "disabled"],
    inject: ["dataName"],

    components: {
        FormInput,
        FormSelect,
        FormTextarea,
        FormHidden,
        FormRadio,
        FormCheckbox,
        FormCheckboxes,
        FormFile,
        FormTeleport,
        FormRichtext,
        FormTeleportField,
        FormRadios,
        FormCounts,
        FormElement,
        FormAddress,
    },

    data() {
        return {
            components: [
                "textarea",
                "select",
                "radio",
                "hidden",
                "checkbox",
                "checkboxes",
                "file",
                "slot",
                "richtext",
                "radios",
                "counts",
                "element",
                "address",
            ],
        };
    },

    methods: {
        /** 
         * determine our field component
         */
        fieldComponent(field) {
            // if we are passed an explicit field type see if we have a matching component
            // if we do return that component's name
            if (
                typeof field.type === "string" &&
                this.components.includes(field.type.toLowerCase())
            ) {
                return `form-${field.type}`;
            }

            return "form-input";
        },
    },
};
</script>

<style scoped>
.group__label {
    grid-column: 1/-1;
}
</style>

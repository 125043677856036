export default [
    {
        path: "",
        name: "application.admin",
        component: () => import("@/views/application/admin/Index.vue"),
    },
    {
        path: "trial-device-information",
        name: "application.admin.trialDeviceInformation",
        component: () =>
            import("@/views/application/admin/TrialDeviceInformation.vue"),
    },
    {
        path: "add-requirement",
        name: "application.admin.addRequirement",
        component: () => import("@/views/application/admin/AddRequirement.vue"),
    },
    {
        path: "collate",
        name: "application.admin.collate",
        component: () => import("@/views/application/admin/Collate.vue"),
    },
    {
        path: "qad",
        name: "application.admin.qad",
        component: () => import("@/views/application/admin/Qad.vue"),
    },
    {
        path: "order-confirmation-details",
        name: "application.admin.order-confirmation-details",
        component: () =>
            import("@/views/application/admin/OrderConfirmationDetails.vue"),
    },
    {
        path: "summary",
        name: "application.admin.summary",
        component: () => import("@/views/application/admin/Summary.vue"),
        meta: {
            frame: "overview-frame",
        },
    },
    {
        path: "notes",
        name: "application.admin.notes",
        component: () => import("@/views/application/admin/Notes.vue"),
        meta: {
            frame: "overview-frame",
        },
    },
    {
        path: "tasks",
        name: "application.admin.tasks",
        component: () => import("@/views/application/admin/Tasks.vue"),
        meta: {
            frame: "overview-frame",
        },
    },
];

<template>
    <!-- visible element -->
    <label
        :for="id"
        :disabled="isDisabled"
        class="form-element__option__image select-none"
        :class="computedClasses"
        v-if="!this.option.hide"
    >
        <img :src="imageUrl"/>
        <!-- name -->
        <div class="form-element__option__image__title">
            <span v-html="text"></span>
        </div>

        <!-- hidden input -->
        <input
            @input="handleInput"
            v-bind="computedAttributes"
            type="radio"
            class="invisible absolute"
        />
    </label>
</template>

<script>
import optionMixin from "./mixins/optionMixin";
import checkIconMixin from "./mixins/checkIconMixin";
import isObject from "@/services/utils/isObject.js";
export default {
    mixins: [optionMixin, checkIconMixin],
    computed: {
        computedAttributes() {
            let attributes = {
                // set id using the ID computed property
                id: this.id,

                // set our checked status using the isChecked computed property
                checked: this.isChecked,

                // set our name using the name provided by our field schema
                name: this.field.name,

                // set our disabled status using the isDisabled computed mixin
                disabled: this.isDisabled,

                // set our classes using our computed classes mixin
                class: this.computedClasses,

                // set our model value from our modelValue prop
                value: this.modelValue,
            };

            // merge in any attributes from our field.attributes property
            if (isObject(this.field.attributes)) {
                attributes = { ...attributes, ...this.field.attributes };
            }

            return attributes;
        },

        isChecked() {
            return this.modelValue === this.option.value;
        },
        imageUrl() {
            // If we start with data:image it is a base64 image, hence we just use it as src
            if (this.option.image.startsWith('data:image')) return this.option.image

            // Otherwise, for now we will assume it is an image from the public drive of the api
            // Hence we need to add the base URL
            return `${process.env.VUE_APP_API_BASE_URL}${this.option.image}`
            
        }
    },

    methods: {
        handleInput() {
            this.$emit("updated", this.option.value);
            //this.updateValue(this.field.name, this.option.value);
        },
    },
};
</script>

<style scoped>

/* IMAGE STYLES */
label img {
  width: 100%;
  max-width: 168px;
  cursor: pointer;
  aspect-ratio: 1.68/1;
}

</style>
//import router from "@/router/index";

// manages our roles
export default {
    state() {
        return {};
    },

    actions: {
    },

    getters: {
        allRoles(_, __, rootState) {
            return rootState.roles;
        },
        roleById(_, __, rootState) {
            return (id) => {
                return rootState.roles[id];
            }
        },
        roleByName(_, __, rootState) {
            return (name) => {
                return Object.values(rootState.roles).find(role => role.name === name) ?? {};
            }
        },
        roleIdByName(_, __, rootState) {
            return (name) => {
                return Object.values(rootState.roles).find(role => role.name === name)?.id ?? 0;
            }
        },
        roleNameById(_, __, rootState) {
            return (id) => {
                return rootState.roles[id]?.name;
            }
        },
        roleTextById(_, __, rootState) {
            return (id) => {
                return rootState.roles[id]?.text;
            }
        },
        roleTextByName(_, __, rootState) {
            return (name) => {
                return Object.values(rootState.roles).find(role => role.name === name)?.text;
            }
        },
    },
};

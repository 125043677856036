<template>
    <error-alert :icon="false" :closeIcon="$store.getters.icons.delete.icon" :closeIconStyle="$store.getters.icons.delete.style" :closeable="isAdmin" @close="deleteRejection">
        <div class="w-full">
            <div class="flex items-center">
                <i class="fad fa-bomb mr-2"></i>
                <span class="font-bold mt-1">Section Rejected by Funding Staff</span>
            </div>
            <div>
                <span class="font-bold mr-1">Reasons:</span>{{ rejection.reasons }}
            </div>
            <div class="text-center italic text-sm mt-2">
                Please correct the described section and re-save the section
            </div>
        </div>
    </error-alert>
</template>

<script>

export default {
    props: {
        rejection: {
            type: Object,
            required: true,
        },
    },
    data(){
        return {
        }
    },
    computed: {
        application(){
            return this.$store.getters["applications/current"] ?? {};
        },

        isAdmin(){
            return this.$store.getters.isAdmin;
        },
    },
    methods: {
        deleteRejection(){
            this.$store.dispatch('applications/clearRejection', this.rejection.id);
        },
    },
};
</script>

<style scoped>
</style>

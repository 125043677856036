export default [
    {
        path: "/docs",
        name: "docs",
        meta: { public: true },
        component: () => import("@/views/dev/docs/Docs.vue"),
        children: [

            /*------------------------------
            *
            * General
            * 
            *-----------------------------*/

            {
                path: "notifications",
                name: "docs.notifications",
                component: () =>
                    import("@/views/dev/docs/general/Notifications.vue"),
            },
            {
                path: "testing-endpoints",
                name: "docs.testingEndpoints",
                component: () =>
                    import("@/views/dev/docs/general/TestingEndpoints.vue"),
            },
            {
                path: "requests",
                name: "docs.requests",
                component: () =>
                    import("@/views/dev/docs/general/Requests.vue"),
            },
            {
                path: "tabs",
                name: "docs.tabs",
                component: () =>
                    import("@/views/dev/docs/state/AcrossTabs.vue"),
            },

            /*------------------------------
            *
            * Components
            * 
            *-----------------------------*/

            {
                path: "buttons",
                name: "docs.buttons",
                component: () =>
                    import("@/views/dev/docs/components/Buttons.vue"),
            },
            {
                path: "cards",
                name: "docs.cards",
                component: () =>
                    import("@/views/dev/docs/components/Cards.vue"),
            },
            {
                path: "modals",
                name: "docs.modals",
                component: () =>
                    import("@/views/dev/docs/components/Modals.vue"),
            },
            {
                path: "alerts",
                name: "docs.alerts",
                component: () =>
                    import("@/views/dev/docs/components/Alerts.vue"),
            },
            {
                path: "observer",
                name: "docs.observer",
                component: () =>
                    import("@/views/dev/docs/components/Observer.vue"),
            },
            {
                path: "clamped-content",
                name: "docs.clampedContent",
                component: () =>
                    import("@/views/dev/docs/components/ClampedContent.vue"),
            },
            {
                path: "response-errors",
                name: "docs.responseErrors",
                component: () =>
                    import("@/views/dev/docs/components/ResponseErrors.vue"),
            },
            {
                path: "toggle-sidebar",
                name: "docs.toggleSidebar",
                component: () =>
                    import("@/views/dev/docs/components/ToggleSidebar.vue"),
            },
            {
                path: "status-icon",
                name: "docs.statusIcon",
                component: () =>
                    import("@/views/dev/docs/components/StatusIcon.vue"),
            },
            {
                path: "base-uploader",
                name: "docs.baseUploader",
                component: () =>
                    import("@/views/dev/docs/components/Uploader.vue"),
            },

            /*------------------------------
            *
            * Quick Form
            * 
            *-----------------------------*/
           
            {
                path: "quick-form",
                name: "docs.quickForm",
                component: () =>
                    import("@/views/dev/docs/forms/Quick-form.vue"),
            },
            {
                path: "input",
                name: "docs.input",
                component: () => import("@/views/dev/docs/forms/Input.vue"),
            },
            {
                path: "textarea",
                name: "docs.textarea",
                component: () => import("@/views/dev/docs/forms/Textarea.vue"),
            },
            {
                path: "address",
                name: "docs.address",
                component: () => import("@/views/dev/docs/forms/Address.vue"),
            },
            {
                path: "radio",
                name: "docs.radio",
                component: () => import("@/views/dev/docs/forms/Radio.vue"),
            },
            {
                path: "radios",
                name: "docs.radios",
                component: () => import("@/views/dev/docs/forms/Radios.vue"),
            },
            {
                path: "counts",
                name: "docs.counts",
                component: () => import("@/views/dev/docs/forms/Counts.vue"),
            },
            {
                path: "checkbox",
                name: "docs.checkbox",
                component: () => import("@/views/dev/docs/forms/Checkbox.vue"),
            },
            {
                path: "file",
                name: "docs.file",
                component: () => import("@/views/dev/docs/forms/File.vue"),
            },
            {
                path: "checkboxes",
                name: "docs.checkboxes",
                component: () =>
                    import("@/views/dev/docs/forms/Checkboxes.vue"),
            },
            {
                path: "select",
                name: "docs.select",
                component: () => import("@/views/dev/docs/forms/Select.vue"),
            },
            {
                path: "hidden",
                name: "docs.hidden",
                component: () => import("@/views/dev/docs/forms/Hidden.vue"),
            },
            {
                path: "group",
                name: "docs.group",
                component: () => import("@/views/dev/docs/forms/Group.vue"),
            },
            {
                path: "element",
                name: "docs.element",
                component: () => import("@/views/dev/docs/forms/Element.vue"),
            },
            {
                path: "teleport",
                name: "docs.teleport",
                component: () => import("@/views/dev/docs/forms/Teleport.vue"),
            },
            {
                path: "validation",
                name: "docs.validation",
                component: () =>
                    import("@/views/dev/docs/forms/Validation.vue"),
            },

            /*------------------------------
            *
            * Home
            * 
            *-----------------------------*/

            {
                path: "",
                name: "docs.home",
                component: () => import("@/views/dev/Home.vue"),
            },
        ],
    },
];

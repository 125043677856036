import startCase from "lodash/startCase";
import hasValue from "@/services/forms/FieldHasValue.js"

export default {
    props: ["field", "option", "modelValue", "disabled"],
    inject: ["updateValue"],
    methods: {},

    computed: {
        id() {
            return `${this.field.name}-${this.option.value}`;
        },

        text() {
            return this.option.text ?? startCase(this.option.value);
        },

        isDisabled() {
            return (
                this.field.attributes?.disabled ||
                this.option?.disabled ||
                this.option?.attributes?.disabled ||
                this.disabled ||
                (this.hasMaxSelection && this.modelValue.length >= this.field.maxSelected && !this.isChecked)
            );
        },

        hasMaxSelection(){
            return this.field.maxSelected !== null && this.field.maxSelected !== undefined;
        },

        checkedIcon() {
            if (this.field.checkedIcon) {
                return "fa-" + this.field.checkedIcon;
            }

            if (this.option?.checkedIcon) {
                return "fa-" + this.option.checkedIcon;
            }

            return "fa-check-square";
        },

        uncheckedIcon() {
            if (this.field.uncheckedIcon) {
                return "fa-" + this.field.uncheckedIcon;
            }

            if (this.option?.uncheckedIcon) {
                return "fa-" + this.option.uncheckedIcon;
            }

            return "far fa-square";
        },

        computedClasses() {
            let classes = "border";

            // if we are checked color our check bar
            if (this.isChecked) {
                classes += " form-element__option--active";
            }

            // if we are disabled turn off our pointer cursor and reduce opacity
            if (this.isDisabled) {
                classes += " opacity-60";
            } else {
                classes += " cursor-pointer";
            }

            // if we are soft required and need a value show a yellow outline
            if ((this.field.required || this.field.softRequired) && !hasValue(this.modelValue, this.field)) {
                classes += " form-element__input--required-highlight";
            }

            // if we set classes for all options
            if (this.field.optionsClass) {
                classes += " " + this.field.optionsClass;
            }

            // if we set classes specifically on this option
            if (this.option?.class) {
                classes += " " + this.option?.class;
            }

            return classes;
        },
    },
};

// base components
import BaseModal from "@/components/global/BaseModal.vue";
import BaseRejection from "@/components/rejections/BaseRejection.vue";
import BaseCard from "@/components/global/BaseCard.vue";
import BaseMain from "@/components/global/BaseMain.vue";
import BaseRequest from "@/components/global/BaseRequest.vue";
import BaseTeleport from "@/components/global/BaseTeleport.vue";
import BaseObserver from "@/components/global/BaseObserver.vue";
import SummaryCard from "@/components/global/SummaryCard.vue";
import SummaryCardItem from "@/components/global/SummaryCardItem.vue";
import BaseToggle from "@/components/global/BaseToggle.vue";

// alerts
import BaseAlert from "@/components/global/alerts/BaseAlert.vue";
import SuccessAlert from "@/components/global/alerts/SuccessAlert.vue";
import NeutralAlert from "@/components/global/alerts/NeutralAlert.vue";
import ErrorAlert from "@/components/global/alerts/ErrorAlert.vue";
import PracticeAlert from "@/components/global/alerts/PracticeAlert.vue";
import InfoAlert from "@/components/global/alerts/InfoAlert.vue";
import WarningAlert from "@/components/global/alerts/WarningAlert.vue";
import LockedAlert from "@/components/application/LockedAlert";
import TermsAlert from "@/components/application/TermsAlert";

// buttons
import BaseButton from "@/components/global/buttons/BaseButton.vue";
import PrimaryButton from "@/components/global/buttons/PrimaryButton.vue";
import SecondaryButton from "@/components/global/buttons/SecondaryButton.vue";
import ActionButton from "@/components/global/buttons/ActionButton.vue";
import ConfirmButton from "@/components/global/buttons/ConfirmButton.vue";
import ConfirmFormButton from "@/components/global/buttons/ConfirmFormButton.vue";

// files
import BaseUploader from "@/components/global/files/BaseUploader.vue";
import UploadWithCollection from "@/components/global/files/UploadWithCollection.vue";
import BaseFileDownload from "@/components/global/files/BaseFileDownload.vue";
import FileCollection from "@/components/global/files/FileCollection.vue";
import HipaaWarning from "@/components/global/files/HipaaWarning.vue";
import FileIcon from "@/components/global/files/FileIcon.vue";

// sidebar
import ToggleSidebar from "@/components/global/sidebar/ToggleSidebar.vue";
import SidebarSection from "@/components/global/sidebar/SidebarSection.vue";
import SidebarItem from "@/components/global/sidebar/SidebarItem.vue";
import SidebarTitle from "@/components/global/sidebar/SidebarTitle.vue";
import SidebarText from "@/components/global/sidebar/SidebarText.vue";
import SidebarDate from "@/components/global/sidebar/SidebarDate.vue";
import SidebarNoItems from "@/components/global/sidebar/SidebarNoItems.vue";

// banners
import BaseBanner from "@/components/global/banners/BaseBanner.vue";
import WarningBanner from "@/components/global/banners/WarningBanner.vue";
import ErrorBanner from "@/components/global/banners/ErrorBanner.vue";

// others
import QuickForm from "@/components/global/QuickForm.vue";
import ApplicationReview from "@/components/application/submit/ApplicationReview";
import ResponseErrors from "@/components/global/ResponseErrors.vue";
import StatusIcon from "@/components/global/StatusIcon.vue";
import SectionTitle from "@/components/global/SectionTitle.vue";
import SectionContent from "@/components/global/SectionContent.vue";
import NotAccordion from "@/components/global/NotAccordion.vue";
import CompleteConfirm from "@/components/global/CompleteConfirm.vue";
import ClampedContent from "@/components/global/ClampedContent.vue";
import StatusCard from "@/components/global/StatusCard.vue";
import Pagination from "@/components/global/pagination/Pagination.vue";
import SelectPagination from "@/components/global/pagination/SelectPagination.vue";
import AobTerms from "@/components/application/insurance/AobTerms"; // only used in one place, but used as a quickform element so needs to be global
import OverviewSectionWrapper from "@/components/application/overview/OverviewSectionWrapper.vue";
import NeutralTitle from "@/components/global/NeutralTitle.vue";
import RegionHeader from "@/components/admin/manageRegions/RegionHeader.vue"; // only used in one place, but used as a quickform element so needs to be global
import DateRange from "@/components/global/DateRange.vue";
import ReviewSection from "@/components/application/submit/ReviewSection.vue";

// modals
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";

export default {
    BaseToggle,
    BaseAlert,
    SuccessAlert,
    ErrorAlert,
    PracticeAlert,
    InfoAlert,
    BaseButton,
    WarningAlert,
    NeutralAlert,
    LockedAlert,
    TermsAlert,
    PrimaryButton,
    SecondaryButton,
    ActionButton,
    ApplicationReview,
    BaseRejection,
    BaseRequest,
    BaseModal,
    QuickForm,
    BaseCard,
    BaseMain,
    BaseTeleport,
    BaseObserver,
    ResponseErrors,
    ConfirmButton,
    ConfirmFormButton,
    StatusIcon,
    ToggleSidebar,
    SidebarSection,
    SidebarItem,
    SidebarTitle,
    SidebarDate,
    SidebarText,
    SidebarNoItems,
    BaseUploader,
    UploadWithCollection,
    BaseFileDownload,
    FileCollection,
    FileIcon,
    HipaaWarning,
    SectionTitle,
    SectionContent,
    NotAccordion,
    BaseBanner,
    WarningBanner,
    ErrorBanner,
    CompleteConfirm,
    ClampedContent,
    StatusCard,
    SummaryCard,
    SummaryCardItem,
    Pagination,
    SelectPagination,
    ConfirmationModal,
    AobTerms,
    OverviewSectionWrapper,
    NeutralTitle,
    RegionHeader,
    DateRange,
    ReviewSection,
};

<template>
    <transition name="fade">
        <div
            v-if="!field.hide"
            class="text-left grid form-element"
            v-bind="field.wrapperAttributes"
            :class="field.wrapperClass"
        >
            <!-- label -->
            <form-label :field="field" :required="isRequired"></form-label>

            <!-- teleport target -->
            <form-teleport :teleport="field.teleport"></form-teleport>

            <!-- input -->
            <textarea
                v-bind="computedAttributes"
                @input="handleInput"
                @blur="validate"
                @focus="clearErrors"
                maxlength="10000"
                v-bind:[dataName]="`${field.name}--input`"
                class="form-element__input mt-1 block w-full text-lg p-2 rounded-md border border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50 order-3"
            ></textarea>

            <!-- errors -->
            <form-error :error="errorText" :field="field" class="order-5"></form-error>
        </div>
    </transition>
</template>

<script>
import fieldMixin from "./mixins/fieldMixin";
import isObject from "@/services/utils/isObject.js";

export default {
    mixins: [fieldMixin],

    data() {
        return {
            defaultRows: 4,
        };
    },

    computed: {
        computedAttributes() {
            let attributes = {
                // set our default rows
                rows:
                    this.field.rows ??
                    this.field.attributes?.rows ??
                    this.defaultRows,

                // set our name using the name provided by our field schema
                name: this.field.name,

                // set our disabled status using the isDisabled computed mixin
                disabled: this.isDisabled,

                // set our classes using our computed classes mixin
                class: this.computedClasses,

                // set our model value from our modelValue prop
                value: this.modelValue,
            };

            // merge in any attributes from our field.attributes property
            if (isObject(this.field.attributes)) {
                attributes = { ...attributes, ...this.field.attributes };
            }

            return attributes;
        },
    },
};
</script>

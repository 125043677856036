<template>
    <!-- wrapper -->
    <transition name="fade">
        <div
            v-if="!field.hide"
            class="form-element text-left w-full grid min-w-full text-lg"
            v-bind="field.wrapperAttributes"
            :class="field.wrapperClass"
        >
            <!-- label -->
            <form-label :field="field" :required="isRequired"></form-label>

            <!-- teleport target -->
            <form-teleport :teleport="field.teleport"></form-teleport>

            <!-- input -->
            <div class="flex flex-wrap items-center relative order-3 text-lg">
                <input
                    @blur="validateAndClearAutocomplete"
                    @input="handleInput"
                    @keydown.enter.prevent="onEnter"
                    @keydown.down.prevent="$refs.autocomplete?.next"
                    @keydown.up.prevent="$refs.autocomplete?.back"
                    @keyup="handleAutocomplete"
                    @focus="clearErrorsAndAutocomplete"
                    class="form-element__input mt-1 block w-full p-2 rounded-md border border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50 order-3"
                    v-bind="computedAttributes"
                    v-bind:[dataName]="`${field.name}--input`"
                    v-focus="field.focused ?? null"
                />
                <!-- add our showPassword toggle if we have a password -->
                <div v-if="field.type === 'password'" class="absolute right-2 text-base">
                    <i
                        class="fad text-white relative form-element__password-toggle cursor-pointer"
                        :class="visibilityIcon"
                        title="Toggle password visibility"
                        @click.prevent="showPassword = !showPassword"
                    ></i>
                </div>
            </div>

            <!-- autofill -->
            <autocomplete-container 
                v-if="hasAutocomplete" 
                :settings="field.autocomplete"
                @selected="onSelected"
                ref="autocomplete"
                v-bind:[dataName]="`${field.name}--autocomplete`"
            /> 


            <!-- errors -->
            <form-error :error="errorText" :field="field" class="order-5"></form-error>
        </div>
    </transition>
</template>

<script>
import fieldMixin from "./mixins/fieldMixin";
import AutocompleteContainer from "./AutocompleteContainer.vue";
import phoneFormatterService from "@/services/utils/phoneFormatterService";
import isObject from "@/services/utils/isObject.js";
//import debounce from "lodash";

export default {
    mixins: [fieldMixin],
    components: {
        AutocompleteContainer,
    },
    data() {
        return {
            showPassword: false,
        };
    },

    computed: {
        type() {
            // if no type is provided default to text
            if (!this.field.type) {
                return "text";
            }

            // is we have the type password decide if we should show the password or not
            if (this.field.type === "password") {
                return this.showPassword ? "text" : "password";
            }

            // otherwise return the given type
            return this.field.type;
        },

        visibilityIcon() {
            return this.showPassword ? "fa-eye" : "fa-eye-slash";
        },

        hasAutocomplete() {
            return typeof this.field.autocomplete?.endpoint === 'string' 
                && typeof this.field.autocomplete?.column === 'string';
        },

        computedAttributes() {
            let attributes = {
                // set our type using the type computed property
                type: this.type,

                // set min using the min computed property
                min: this.field.min,

                // set our name using the name provided by our field schema
                name: this.field.name,

                // set our disabled status using the isDisabled computed mixin
                disabled: this.isDisabled,

                // set our classes using our computed classes mixin
                class: this.computedClasses,

                // set our model value from our modelValue prop
                value: this.modelValue,

                // set a default maxLength of 255
                maxLength: 255,
            };

            if(this.hasAutocomplete){
                attributes.list = "autocomplete";
                attributes.autocomplete = "off";
            }

            // merge in any attributes from our field.attributes property
            if (isObject(this.field.attributes)) {
                attributes = { ...attributes, ...this.field.attributes };
            }

            return attributes;
        },
    },

    methods: {
        handleAutocomplete(event) {
            if(this.hasAutocomplete){
                this.$refs.autocomplete.debounceInput(event);
            }
        },

        clearErrorsAndAutocomplete() {
            this.clearErrors();
            
            if(this.hasAutocomplete){
                this.$refs.autocomplete?.clear();
            }
        },

        onSelected(value) {
            // if we are passed a null value do nothing
            if(value === null){
                return;
            }

            this.updateValue(this.field.name, value);
        },

        validateAndClearAutocomplete() {
            if(this.field.type === 'tel')
            {
                const value = phoneFormatterService.format(this.modelValue);
                this.updateValue(this.field.name, value);
            }
            this.validate();
            this.$refs.autocomplete?.clear();
        }
    }
};
</script>

<style scoped>
.form-element__password-toggle {
    top: 3px;
}
</style>

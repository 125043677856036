//import router from "@/router/index";

// manages our user state
export default {
    namespaced: true,

    state() {
        return {
            specialists: null,
            consultants: null,
            allTaskTypes: null,
            generalAdminLinks: [
                {
                    route: "admin.index",
                    icon: "user-lock",
                    title: "Admin Home",
                    hideInIndex: true,
                },
                {
                    route: "admin.tasks",
                    icon: "list-check",
                    title: "All Tasks",
                    description: "All of your tasks in a single view"
                },
                {
                    route: "admin.taskTypes",
                    icon: "list-check",
                    title: "Manage Task Types",
                    ownerAdmin: true,
                    description:
                        "Manage the available task types",
                },
                {
                    route: "admin.specialistRegions",
                    icon: "book-atlas",
                    title: "Specialist Regions",
                    ownerAdmin: true,
                    description:
                        "Manage the regions assigned to each funding specialist",
                },
                {
                    route: "admin.consultantRegions",
                    icon: "book-atlas",
                    title: "Consultant Regions",
                    ownerAdmin: true,
                    description:
                        "Manage the states/regions assigned to each consultant",
                },
                {
                    route: "admin.manageDocuments",
                    icon: "clipboard-list",
                    title: "Unassigned Documents",
                    admin: true,
                    description:
                        "Assign new documents to states and/or applications",
                },
                {
                    route: "admin.systemAdmin",
                    icon: "gear",
                    title: "System Admin",
                    ownerAdmin: true,
                    description:
                        "Reassign contributors and owner admin tools",
                },
            ],
            applicationAdminLinks: [
                {
                    title: "Admin Tools",
                    route: "application.admin",
                    hideInIndex: true,
                },
                {
                    title: "Trial Device Information",
                    icon: "calendar",
                    route: "application.admin.trialDeviceInformation",
                    description: "View significant trial device information from QAD",
                },
                {
                    title: "Add Requirement",
                    icon: "file-plus",
                    route: "application.admin.addRequirement",
                    description:
                        "Add a required document or subsection to an application",
                },
                {
                    title: "Collate",
                    icon: "merge",
                    route: "application.admin.collate",
                    description:
                        "Collate uploads and sections of an application into a single PDF",
                },
                {
                    title: "Enter QAD/Brightree Information",
                    icon: "info",
                    route: "application.admin.qad",
                    description:
                        "Enter QAD and Brightree information such as RMA number, Sales Order number, CA Number, and Customer ID. ",
                },
                {
                    title: "Order Confirmation Details",
                    icon: "merge",
                    route: "application.admin.order-confirmation-details",
                    description:
                        "Set the details of the order for this application",
                },
                {
                    title: "Application Summary",
                    route: "application.admin.summary",
                    description: "View an summary of this application",
                    icon: "clipboard-list",
                },
            ],
        };
    },

    mutations: {
        // set our super-admins
        setSpecialists(state, specialists) {
            state.specialists = specialists;
        },

        // set our admins
        setConsultants(state, consultants) {
            state.consultants = consultants;
        },

        // set our task types
        setAllTaskTypes(state, taskTypes) {
            state.allTaskTypes = taskTypes;
        }
    },

    actions: {
        /**
         * Load super-admin data from the API
         *
         * @param {Context} context
         */
        async loadSpecialists({ dispatch }) {
            let response;

            try {
                response = await this.$http.get("funding/admins/specialists", {
                    successNotification: false,
                });
            } catch (error) {
                if (process.env.VUE_APP_NODE_ENV !== "production") {
                    console.error(
                        `Failed to load title data: ${JSON.stringify(response)}`
                    );
                }
                return;
            }

            // commit our titles
            dispatch("setSpecialists", response?.data);
        },

        /**
         * Set our super-admins in the store
         *
         * @param {object} context
         * @param {array} specialists
         */
        setSpecialists({ commit }, specialists) {
            commit("setSpecialists", specialists);
        },

        /**
         * Load admin data from the API
         *
         * @param {Context} context
         */
        async loadConsultants({ dispatch }) {
            let response;

            try {
                response = await this.$http.get("funding/admins/consultants", {
                    successNotification: false,
                });
            } catch (error) {
                if (process.env.VUE_APP_NODE_ENV !== "production") {
                    console.error(
                        `Failed to load title data: ${JSON.stringify(response)}`
                    );
                }
                return;
            }

            // commit our titles
            dispatch("setConsultants", response?.data);
        },

        /**
         * Set our admins in the store
         *
         * @param {object} context
         * @param {array} consultants
         */
        setConsultants({ commit }, consultants) {
            commit("setConsultants", consultants);
        },

        /**
         * Load all task type data from the API (including trashed), note this is used just for the admin page
         * and not for the application pages. That data should be loaded separately (and without the trashed).
         *
         * @param {Context} context
         */
        async loadTaskTypes({ dispatch }) {
            let response;

            try {
                response = await this.$http.get("funding/task-types", {
                    successNotification: false,
                });
            } catch (error) {
                if (process.env.VUE_APP_NODE_ENV !== "production") {
                    console.error(
                        `Failed to load title data: ${JSON.stringify(response)}`
                    );
                }
                return;
            }

            // commit our titles
            dispatch("setAllTaskTypes", response?.data);
        },

        /**
         * Set our task types in the store
         *
         * @param {object} context
         * @param {array} taskTypes
         */
        setAllTaskTypes({ commit }, taskTypes) {
            commit("setAllTaskTypes", taskTypes);
        },

        /**
         * When we edit/add/delete a task type, we need to update our task types in the store, 
         * and update our root task types in the index (after removing the deleted task types).
         * 
         * @param {object} context
         * @param {array} taskTypes
         */
        saveUpdatedTaskTypes({ commit }, taskTypes) {
            commit("setAllTaskTypes", taskTypes);

            // Set our root task types (what is usually supplied by the fundingData call to the API)
            // It will be all of our task types, minus the trashed ones.
            let taskTypesWithoutTrashed = taskTypes.filter(taskType => !taskType.deleted_at);
            commit("setTaskTypes", taskTypesWithoutTrashed, { root: true });
        },
    },

    getters: {
        specialists(state) {
            return state.specialists;
        },

        consultants(state) {
            return state.consultants;
        },

        allTaskTypes(state) {
            return state.allTaskTypes;
        },

        generalAdminLinks(state) {
            return state.generalAdminLinks ?? [];
        },

        applicationAdminLinks(state) {
            return state.applicationAdminLinks ?? [];
        },
    },
};

<template>
    <sidebar-section
        v-if="isAdmin"
        :hideInPrivacyMode="true"
        title="tasks"
        :count="total"
        :showViewAll="hasAnyTasks"
        :viewAllCount="applicationTotal"
        :showAddItem="$route.name !== 'dashboard' && !isClosed"
        @addItem="onCreateTaskClick(application)"
        @viewAll="$router.push(viewAllRoute)"
        class="w-[250px]"
    >
        <!-- show filters button -->
        <template #sidebar-header-button>
            <action-button
                icon="file-csv"
                title="Export this table to csv file. Max: 1000"
                class="bg-transparent hover:bg-headerBlue"
                @click="exportCsvFile"
            ></action-button>
            <div
                class="cursor-pointer flex items-center gap-1 text-secondary-600"
                @click="showFilters = !showFilters"
            >
                <span class="text-xs">
                    {{ this.showFilters ? "hide" : "show" }} 
                    <span v-if="activeFilters > 0" class="rounded-full px-[5px] text-black bg-accentBlue">{{ activeFilters }}</span> 
                    filters
                </span>
                <i class="fa fa-list" aria-hidden="true"></i>
            </div>
        </template>

        <div
            v-if="clickedThroughTask && singleApplication"
            class="p-2 border mb-2 rounded border-secondary-900 grid gap-1"
        >
            <!-- clicked through task -->
            <div class="flex flex-col justify-end">
                <sidebar-title
                    class="text-center !text-secondary-300 !text-xs uppercase"
                >
                        <span class="relative left-2">
                            Selected Task
                        </span>
                        
                        <action-button 
                            icon="times" 
                            :minimal='true'
                            class="ml-auto -mt-1 opacity-50 hover:opacity-100 float-right"
                            title="Clear selected task"
                            @click="$store.dispatch('tasks/clearClickedTask')" 
                        />
                </sidebar-title>
            </div>

            <task-list-item
                v-if="clickedThroughTask.application"
                :task="clickedThroughTask"
                @click="onClickTask(clickedThroughTask)"
            />
        </div>

        <!-- filters -->
        <Transition>
            <div v-show="showFilters">
                <!-- clear filters -->
                <div
                    class="cursor-pointer mb-2 p-1 rounded text-xs w-fit border border-secondary-700"
                    @click="clearFilters"
                >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                    Clear Filters
                </div>

                <!-- search tasks -->
                <div class="relative">
                    <input
                        class="w-full h-7 mb-2 input-width pr-8 py-px px-2.5 rounded border border-secondary-700 bg-transparent text-xxs placeholder-secondary-700"
                        placeholder="SEARCH TASKS"
                        v-model="search"
                        @keyup.enter="startSearch"
                    />
                    <div
                        class="absolute top-[2px] right-2 cursor-pointer"
                        @click="startSearch"
                    >
                        <i class="fa-regular"
                           :class="searchLengthRequirmentMet ? 'fa-magnifying-glass' : 'fa-magnifying-glass-minus'"
                           aria-hidden="true"></i>
                    </div>
                </div>

                <!-- toggles -->
                <task-filter-toggle
                    id="overdue-toggle"
                    title="Show only overdue tasks"
                    v-model:toggleValue="show_overdue"
                    @update:toggleValue="startSearch"
                />

                <task-filter-toggle
                    id="completed-toggle"
                    title="Include Completed Tasks"
                    v-model:toggleValue="show_completed"
                    @update:toggleValue="startSearch"
                />

                <task-filter-toggle
                    id="all-toggle"
                    title="Show only my tasks"
                    v-model:toggleValue="show_only_my"
                    @update:toggleValue="startSearch"
                />
                <task-filter-toggle
                    id="unassigned-toggle"
                    title="Show only unassigned tasks"
                    v-model:toggleValue="show_only_unassigned"
                    @update:toggleValue="startSearch"
                />

                <!-- dropdowns -->
                <div class="w-full flex items-center flex-wrap mt-4 gap-2.5 mb-2">
                    <DropdownSelector
                        name="TASK TYPE"
                        :items="taskTypes"
                        searchValue="type"
                        itemValue="type"
                        itemText="type"
                        :selection="names"
                        setTheme="dark"
                        @onSelectAll="
                            names = taskTypes.map((x) => x.type);
                            startSearch();
                        "
                        @onDeselectAll="
                            names = [];
                            startSearch();
                        "
                        @onSelectionChange="
                            names = $event;
                            startSearch();
                        "
                    ></DropdownSelector>
                    <DropdownSelector
                        v-if="!singleApplication"
                        name="INSURANCES"
                        :items="all_insurances"
                        searchValue="text"
                        itemValue="value"
                        itemText="text"
                        :selection="insurances"
                        setTheme="dark"
                        @onSelectAll="
                            insurances = all_insurances.map((x) => x.value);
                            startSearch();
                        "
                        @onDeselectAll="
                            insurances = [];
                            startSearch();
                        "
                        @onSelectionChange="
                            insurances = $event;
                            startSearch();
                        "
                    ></DropdownSelector>
                    <DropdownSelector
                        v-if="!singleApplication"
                        name="APPLICATION TYPE"
                        :items="all_types"
                        searchValue="value"
                        itemValue="value"
                        itemText="value"
                        :selection="types"
                        setTheme="dark"
                        @onSelectAll="
                            types = all_types.map((x) => x.value);
                            startSearch();
                        "
                        @onDeselectAll="
                            types = [];
                            startSearch();
                        "
                        @onSelectionChange="
                            types = $event;
                            startSearch();
                        "
                    ></DropdownSelector>
                    <DropdownSelector
                        v-if="!singleApplication"
                        name="DEVICES"
                        :items="all_devices"
                        searchValue="device"
                        itemValue="id"
                        itemText="device"
                        :selection="devices"
                        setTheme="dark"
                        @onSelectAll="
                            devices = all_devices.map((x) => x.id);
                            startSearch();
                        "
                        @onDeselectAll="
                            devices = [];
                            startSearch();
                        "
                        @onSelectionChange="
                            devices = $event;
                            startSearch();
                        "
                    ></DropdownSelector>

                    <state-dropdown-selector
                        v-if="!singleApplication"
                        :items="all_states"
                        :selection="states"
                        setTheme="dark"
                        @onSelectAll="
                            states = all_states.map((x) => x.value);
                            startSearch();
                        "
                        @onDeselectAll="
                            states = [];
                            startSearch();
                        "
                        @onSelectionChange="
                            states = $event;
                            startSearch();
                        "
                    />
                    
                    <!--
                    <DropdownSelector
                        v-if="!singleApplication"
                        name="STATES"
                        :items="all_states"
                        searchValue="value"
                        itemValue="value"
                        itemText="value"
                        :selection="states"
                        setTheme="dark"
                        @onSelectAll="
                            states = all_states.map((x) => x.value);
                            startSearch();
                        "
                        @onDeselectAll="
                            states = [];
                            startSearch();
                        "
                        @onSelectionChange="
                            states = $event;
                            startSearch();
                        "
                    ></DropdownSelector>
                    -->
                </div>

                <!-- sort -->
                <div class="text-secondary-700 text-xxs">Sort by:</div>
                <select
                    v-model="sort"
                    @change="startSearch()"
                    class="mb-4 text-sm bg-transparent border border-secondary-700 text-secondary-500 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                >
                    <option value="-created_at">Creation date (newest)</option>
                    <option value="created_at">Creation date (oldest)</option>
                    <option value="-due_by">Due date (newest)</option>
                    <option value="due_by">Due date (oldest)</option>
                    <option value="-priority">Priority</option>
                </select>
            </div>
        </Transition>

        <!-- if our tasks are loaded, show this stuff -->
        <template v-if="tasksLoaded">
            <!-- task list -->
            <div  class="grid gap-2">
                <task-list-item
                    v-for="userTask in tasksToShow"
                    :key="userTask.id"
                    :task="userTask"
                    @click="onClickTask(userTask)"
                />

                <sidebar-no-items :count="tasksToShow.length">
                    no matching tasks
                </sidebar-no-items>
            </div>

            <!-- pagination -->
            <div v-if="pagesToShow.length > 1" class="text-sm">
                <div class="flex items-center justify-center">
                    <div class="px-1 cursor-pointer" @click="onPageChange(1)">
                        &laquo;
                    </div>
                    <div
                        v-for="p in pagesToShow"
                        :key="p"
                        class="px-1 cursor-pointer"
                        :class="{ underline: p === page }"
                        @click="onPageChange(p)"
                    >
                        {{ p }}
                    </div>
                    <div class="px-1 cursor-pointer" @click="onPageChange(pages)">
                        &raquo;
                    </div>
                </div>
            </div>
        </template>

        <!-- if our tasks aren't loaded show this -->
        <div v-if="!tasksLoaded" class="grid gap-2">
            <div class="p-3 bg-headerBlue/60 rounded pl-3 h-[96px] transition-all animate-pulse flex justify-center items-center">
                <span class="fas fa-spinner-third fa-spin"></span>
                <span class="ml-2 text-sm">loading tasks...</span>
            </div>
            
            <div class="p-3 bg-headerBlue/60 rounded pl-3 h-[96px] transition-all animate-pulse flex justify-center items-center">
            </div>
            <div class="p-3 bg-headerBlue/60 rounded pl-3 h-[96px] transition-all animate-pulse flex justify-center items-center">
            </div>
            <div class="p-3 bg-headerBlue/60 rounded pl-3 h-[96px] transition-all animate-pulse flex justify-center items-center">
            </div>
            <div class="p-3 bg-headerBlue/60 rounded pl-3 h-[96px] transition-all animate-pulse flex justify-center items-center">
            </div>
        </div>
       
    </sidebar-section>
</template>

<script>
import statesOptions from "@/services/forms/StatesOptions.js";
import DropdownSelector from "@/components/dashboard/DropdownSelector";
import StateDropdownSelector from "@/components/dashboard/StateDropdownSelector";
import TaskListItem from "./TaskListItem";
import TaskFilterToggle from "./TaskFilterToggle";
import downloadFile from "@/services/utils/downloadFile";

export default {
    components: {
        DropdownSelector,
        TaskListItem,
        TaskFilterToggle,
        StateDropdownSelector,
    },

    mounted() {
        // if we are on the all applications page clear our filters
        // and load our search
        if(!this.singleApplication){
            this.startSearch();
        }

        this.today = new Date();

        // load our clickedTask if we need to
        if (
            this.isAdmin &&
            this.singleApplication &&
            this.$route.query.task_click_through &&
            this.clickedThroughTask?.id !== this.$route.query.task_click_through
        ) {
            this.$store.dispatch("tasks/getClickedTask", {
                task_click_through_id: this.$route.query.task_click_through,
                fromApplication: this.currentApplication?.id,
            });
        }

        // if we have a clickedThroughTask but we don't have a task_click_through query 
        // parameter then we need to handle this mismatch
        if(this.singleApplication 
            && !this.$route.query.task_click_through 
            && this.clickedThroughTask
        ){
            this.manageOrphanClickedThroughTask();
        }
    },

    data() {
        return {
            today: null,
            task_click_through_id: null,
            showFilters: false,
            allTaskPriorities: [
                { text: "high", value: 1 },
                { text: "low", value: 0 },
            ],
        };
    },
    methods: {
        clearFilters() {
            this.$store.dispatch("tasks/clearFilters");
            this.startSearch();
        },
        onPageChange(page) {
            if (page <= 0 || page > this.pages) return;
            this.page = page;
            this.$store.dispatch("tasks/getUserTasks", { pageChange: true });
        },
        checkOverdue(task) {
            let date = task.due_by;
            let due_date = new Date(date);
            let today = new Date();
            return !task.completed_at && due_date < today ? "OVERDUE" : "";
        },
        onCreateTaskClick() {
            let application = this.currentApplication;
            let taskData = {
                createMode: true,
                selectedTask: {
                    alias: application.alias,
                },
            };
            this.$store.dispatch("tasks/openTaskModal", taskData);
        },
        onClickTask(task) {
            this.$store.dispatch("tasks/openTaskModal", {
                selectedTask: task,
                createMode: false,
                editMode: false,
            });
        },
        startSearch() {
            if( this.searchLengthRequirmentMet ) {
                this.$store.dispatch("tasks/getUserTasks");
            }
        },
        manageOrphanClickedThroughTask(){
            // if our orphaned task doesn't match the current application clear it
            if(this.clickedThroughTask.application_id !== this.currentApplication?.id){
                this.$store.dispatch("tasks/clearClickedTask");
            }

            // otherwise add the query param to the url
            const query = { ...this.$route.query};
            query.task_click_through = this.clickedThroughTask?.id;
            this.$router.replace({ query });
        },
        async exportCsvFile() {
            const endpoint = `funding/export/tasks-table-csv`;
            const params = this.$store.getters['tasks/tasksParams'];
            if (
                this.currentApplication &&
                this.$route.path.includes(`application`)
            ) {
                params.application_id = this.currentApplication.id;
            }
            const response = await this.$http.post(endpoint, params,{
                successNotification: false
            });
            if (response.data) {
                downloadFile(response.data, "Task_Table_Export.csv", {csv: true});
            }
            
        },
    },
    computed: {
        activeFilters() {
            return this.$store.getters['tasks/activeFiltersCount'];
        },

        tasksLoaded() {
            return this.$store.getters['tasks/userTasksLoaded'];
        },

        taskTypes() {
            return this.$store.getters['tasks/taskTypes'];
        },

        params(){
            return this.$store.getters['tasks/tasksParams'] ?? {};
        },

        pagesToShow() {
            let array = [];
            let start = this.page - 4 >= 1 ? this.page - 4 : 1;
            let end = this.page + 4 <= this.pages ? this.page + 4 : this.pages;
            let startrem = -(this.page - start) + 4;
            let endrem = -(end - this.page) + 4;
            if (startrem) {
                end =
                    end + startrem <= this.pages ? end + startrem : this.pages;
            }
            if (endrem) {
                start = start - endrem >= 1 ? start - endrem : 1;
            }
            for (let i = start; i <= end; i++) {
                array.push(i);
            }
            return array;
        },
        currentApplication() {
            return this.$store.getters["applications/current"];
        },
        isClosed() {
            return this.$store.getters["applications/currentIsClosed"];
        },
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
        total() {
            return this.$store.getters["tasks/total"] ?? 0;
        },
        applicationTotal() {
            return this.$store.getters["tasks/applicationTotal"] ?? 0;
        },
        hasAnyTasks() {
            if(this.singleApplication){
                return this.applicationTotal > 0;
            }

            return this.total > 0;
        },
        states: {
            get() {
                return this.params.states;
            },
            set(value) {
                this.$store.dispatch("tasks/setParam", { param: 'states', value: value });
            },
        },
        names: {
            get() {
                return this.params.names;
            },
            set(value) {
                this.$store.dispatch("tasks/setParam", { param: 'names', value: value });
            },
        },
        insurances: {
            get() {
                return this.params.insurances;
            },
            set(value) {
                this.$store.dispatch("tasks/setParam", { param: 'insurances', value: value });
            },
        },
        devices: {
            get() {
                return this.params.devices;
            },
            set(value) {
                this.$store.dispatch("tasks/setParam", { param: 'devices', value: value });
            },
        },
        types: {
            get() {
                return this.params.types;
            },
            set(value) {
                this.$store.dispatch("tasks/setParam", { param: 'types', value: value });
            },
        },
        sort: {
            get() {
                return this.params.sort;
            },
            set(value) {
                this.$store.dispatch("tasks/setParam", { param: 'sort', value: value });
            },
        },
        priorities: {
            get() {
                return this.params.priorities;
            },
            set(value) {
                this.$store.dispatch("tasks/setParam", { param: 'priorities', value: value });
            },
        },
        show_overdue: {
            get() {
                return this.params.show_overdue;
            },
            set(value) {
                this.$store.dispatch("tasks/setParam", { param: 'show_overdue', value: value });
            },
        },
        show_all: {
            get() {
                return this.params.show_all;
            },
            set(value) {
                this.$store.dispatch("tasks/setParam", { param: 'show_all', value: value });
            },
        },
        page: {
            get() {
                return this.params.page;
            },
            set(value) {
                this.$store.dispatch("tasks/setParam", { param: 'page', value: value });
            },
        },
        pages: {
            get() {
                return this.params.pages;
            },
            set(value) {
                this.$store.dispatch("tasks/setParam", { param: 'pages', value: value });
            },
        },
        show_only_my: {
            get() {
                return !this.show_all;
            },
            set(value) {
                this.show_all = !value;
            },
        },
        show_only_unassigned: {
            get() {
                return this.params.show_only_unassigned;
            },
            set(value) {
                this.$store.dispatch("tasks/setParam", { param: 'show_only_unassigned', value: value });
            },
        },
        show_completed: {
            get() {
                return this.params.show_completed;
            },
            set(value) {
                this.$store.dispatch("tasks/setParam", { param: 'show_completed', value: value });
            },
        },
        search: {
            get() {
                return this.params.search;
            },
            set(value) {
                this.$store.dispatch("tasks/setParam", { param: 'search', value: value });
            },
        },
        all_devices() {
            return this.$store.getters.sortedAllDevices ?? [];
        },
        all_types() {
            return [
                { value: "trial" },
                { value: "purchase" },
                { value: "repair" },
            ];
        },
        all_insurances() {
            return [
                { value: "medicare", text: "Medicare" },
                { value: "medicaid", text: "Medicaid" },
                { value: "commercial", text: "Commercial Insurance" },
                { value: "additional", text: "Additional Insurance" },
                { value: "additional2", text: "Additional Insurance 2" },
            ];
        },
        all_states() {
            return statesOptions;
        },
        filtered_states() {
            return this.all_states.filter((x) =>
                x.value.toLowerCase().includes(this.search.toLowerCase())
            );
        },
        clickedThroughTask() {
            return this.$store.getters["tasks/clickedTask"];
        },
        userTasks() {
            if (!this.$store.getters["tasks/userTasks"]) return [];
            return this.$store.getters["tasks/userTasks"];
        },
        tasksToShow() {
            if (
                this.currentApplication &&
                this.$route.path.includes(`application`)
            ) {
                return this.userTasks.filter(
                    (x) =>
                        x.id.toString() !== this.task_click_through_id &&
                        x.application
                );
            } else {
                return this.userTasks;
            }
        },
        singleApplication() {
            return !! this.currentApplication?.id;
        },
        viewAllRoute() {
            return this.singleApplication ?
                { name: 'application.admin.tasks' } : { name: "admin.tasks" };
        },
        searchLengthRequirmentMet() {
            return (this.search.length === 0 || this.search.length > 2);
        },
    },
};
</script>

<style scoped>
.v-enter-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>

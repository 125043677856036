<template>
    <!-- wrapper -->
    <transition name="fade">
        <div
            v-if="!field.hide"
            class="form-element text-left w-full grid min-w-full"
            v-bind="field.wrapperAttributes"
            :class="field.wrapperClass"
        >
            <!-- label -->
            <form-label :field="field" :required="isRequired" tag="p"></form-label>

            <!-- teleport target -->
            <form-teleport :teleport="field.teleport"></form-teleport>

            <label
                class="form-element__input grid justify-center mt-2 w-full text-lg p-4 lg:py-10 rounded-md border-4 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50 order-3"
                :for="field.name"
                :disabled="isDisabled"
                :class="fileComputedClasses"
                @dragenter.prevent="dragging = true"
                @dragover.prevent="dragging = true"
                @dragleave.prevent="dragging = false"
                @drop.prevent="dropFile"
                tabindex="0" 
            >
                <!-- icon -->
                <i class="fad fa-upload text-center text-3xl"></i>
                
                <!-- text -->
                <div class="flex items-center justify-center gap-2">
                    <div :class="{'text-gray-400' : !this.modelValue}">{{ text }}</div>

                    <!-- if we do have a file show a delete button -->
                    <action-button
                        v-if="modelValue"
                        @click.prevent.stop="removeFile"
                        title="Remove this file"
                        class="times text-red-500 ml-auto"
                        icon="times"
                    />
                </div>
            </label>

            <!-- input -->
            <div class="flex flex-wrap items-center mt-1 relative order-3">
                <input
                    ref="input"
                    @blur="validate"
                    @input="handleInput"
                    @focus="clearErrors"
                    v-bind="computedAttributes"
                    class="invisible absolute"
                    v-bind:[dataName]="`${field.name}--input`"
                />
            </div>

            <!-- errors -->
            <form-error :error="errorText" :field="field" class="order-5"></form-error>
        </div>
    </transition>
</template>

<script>
import fieldMixin from "./mixins/fieldMixin";
import isObject from "@/services/utils/isObject.js";

export default {
    mixins: [fieldMixin],

    data() {
        return {
            defaultMaxFileSize: 50, // MBs
            dragging: false,
        };
    },

    computed: {
        maxFileSize() {
            return this.field.maxFileSize ?? this.defaultMaxFileSize;
        },

        text() {
            return this.modelValue ? this.modelValue.name : "CHOOSE A FILE"; 
        },

        fileComputedClasses() {
            let classes = [...this.computedClasses];

            if(!this.modelValue && !this.dragging){
                classes.push("border-dashed");
            }

            if(this.dragging){
                classes.push("border-primary-400");
            } else {
                classes.push("border-gray-300");
            }

            return classes;
        },

        computedAttributes() {
            let attributes = {
                // set our type
                type: "file",

                // set our name & id using the name provided by our field schema
                name: this.field.name,
                id: this.field.name,

                // set our disabled status using the isDisabled computed mixin
                disabled: this.isDisabled,

                // set our classes using our computed classes mixin
                class: this.computedClasses,

                // set our accepted file types
                accept: this.field.accept,

                // set our model value from our modelValue prop
                //value: this.modelValue,
            };

            // merge in any attributes from our field.attributes property
            if (isObject(this.field.attributes)) {
                attributes = { ...attributes, ...this.field.attributes };
            }

            return attributes;
        },
    },

    methods: {
        handleInput(event) {
            this.clearErrors();

            const file = event.target.files[0];

            if(file){
                const fileSize = file.size / 1024 / 1024;

                // check file size
                if( fileSize > this.maxFileSize ){
                    this.form.errors.set(
                        this.field.name,
                        `File size (${ fileSize.toFixed(1) }MB) exceeds the limit of ${ this.maxFileSize }MB`
                    );
                }
            }

            this.updateValue(this.field.name, file);
        },

        removeFile() {
            this.$refs.input.value = '';
            this.clearErrors();
            this.updateValue(this.field.name, '');
        },

        dropFile(event) {
            this.dragging = false;
            const files = event.dataTransfer.files;
            const input = this.$refs.input;

            input.files = files;
            this.handleInput({target: input });
        }
    }
};
</script>

<style scoped>

</style>

import objectPropertiesHaveValue from "../services/utils/objectPropertiesHaveValue";

export default {
    namespaced: true,

    state() {
        return {
            insuranceTypes: {
                medicare: "Medicare",
                medicaid: "Medicaid",
                commercial: "Commercial",
                additional: "Additional",
                additional2: "Additional 2",
            },
            payerLevels: {
                1: "Primary",
                2: "Secondary",
                3: "Tertiary"
            }
        };
    },

    mutations: {},

    actions: {},

    getters: {
        insuranceTypes(state){
            return state.insuranceTypes;
        },
        payerLevel(state) {
            return level => state.payerLevels[level]
        },
        insuranceInfo(_state, _getters, rootState){
            return rootState.applications.current?.insurance_info ?? {};
        },

        insuranceData(_state, _getters, rootState){
            return rootState.applications.current?.insurance_info?.insurance_data ?? [];
        },

        activeInsuranceTypes(state, getters, _rootState, _rootGetters){
            const activeInsurances = [];
            
            Object.entries(state.insuranceTypes).forEach(([key, label]) => {
                const data = getters.insuranceData.find(data => data.insurance_type === key);
                if(data){
                    activeInsurances.push({ key, label, data });
                }
            });

            return activeInsurances;
        },

        sections(_state, getters, _rootState, rootGetters) {
            const sections = {
                selected: getters.selected,
                approve: getters.approve,
            };

            // cycle through our non-signature sections and forward to the appropriate getter
            getters.activeInsuranceTypes.forEach(type => {
                sections[type.key] = insuranceInfoCompleted(type.data, rootGetters);
                sections[type.key + 'Aob'] = insuranceAobCompleted(type.data, rootGetters);
            });

            return sections;
        },

        /**
         * Have we selected our insurances?
         *
         * @returns int (status_id)
         */
        selected(_state, getters, _rootState, rootGetters) {
            if(getters.insuranceData.length > 0){
                return rootGetters.getStatusId("COMPLETED");
            }

            return rootGetters.getStatusId("NOT_STARTED");
        },

        /**
         * Have we approved our insurances?
         * 
         * @returns 
         */
        approve(_state, getters, _rootState, rootGetters) {
            const validStatuses = [
                rootGetters.getStatusId("COMPLETED"),
                //rootGetters.getStatusId("UNDER_REVIEW"),
            ];

            if(validStatuses.includes(getters.insuranceInfo.status_id)){
                return rootGetters.getStatusId("COMPLETED");
            }

            return rootGetters.getStatusId("NOT_STARTED");
        },

    },
};

/**
 * Is the given insurance data info complete 
 * 
 * @param {object} insuranceData 
 * @param {object} rootGetters 
 * @returns int (status_id)
 */
function insuranceInfoCompleted(insuranceData, rootGetters) {
    const requiredProperties = ["insurance_id", "insurance_name"];

    const completionArray = [
        objectPropertiesHaveValue(insuranceData, requiredProperties),
        insuranceInfoHasCard(insuranceData, "front", rootGetters),
        insuranceInfoHasCard(insuranceData, "back", rootGetters),
    ];

    return rootGetters.getStatusIdFromCompletionArray(completionArray);
}

/**
 * Does the given insurance data have a card that matches the given type
 * 
 * @param {object} insuranceData 
 * @param {string} side 
 * @param {object} rootGetters 
 * @returns 
 */
function insuranceInfoHasCard(insuranceData, side, rootGetters) {
    const typeId = rootGetters.getUploadTypeId(`${insuranceData.insurance_type}_${side}`)
    
    // check if a matching upload exists
    const uploads = insuranceData.uploads ?? [];
    const uploadExists = uploads.some(upload => 
            upload.data_upload_type_id === typeId 
            && upload.status_id !== rootGetters.getStatusId("REJECTED")
        );

    // if it does we are completed
    if(typeId && uploadExists){
        return rootGetters.getStatusId("COMPLETED");
    }

    return rootGetters.getStatusId("NOT_STARTED");
}

/**
 * Has the given insurances AOB been completed?
 * 
 * @param {object} insuranceData 
 * @param {object} rootGetters 
 * @returns int (status_id)
 */
function insuranceAobCompleted(insuranceData, rootGetters) {

    // if we have uploaded our AOB then we are completed
    if(insuranceHasUploadedAob(insuranceData, rootGetters)){
        return rootGetters.getStatusId("COMPLETED");
    }

    // otherwise return the status_id of the AOB
    return insuranceData.aob?.status_id;
}

/**
 * Does the given insurance data have an uploaded AOB?
 * 
 * @param {object} insuranceData 
 * @param {object} rootGetters 
 * @returns boolean
 */
function insuranceHasUploadedAob(insuranceData, rootGetters) {
    return insuranceData.uploads?.some(upload => 
        upload.data_upload_type_id === rootGetters.getUploadTypeId("aob") 
        && upload.status_id !== rootGetters.getStatusId("REJECTED")
    );
}


<template>
    <!-- visible element -->
    <label
        :for="id"
        :disabled="isDisabled"
        class="form-element__option select-none"
        :class="computedClasses"
        v-if="!this.option.hide"
    >
        <!-- icon -->
        <i
            v-if="!hideIcon"
            class="form-element__option-icon text-lg mr-2"
            :class="checkIcon"
        ></i>

        <!-- name -->
        <span v-html="text"></span>

        <!-- hidden input -->
        <input
            @input="handleInput"
            v-bind="computedAttributes"
            type="radio"
            class="invisible absolute"
        />
    </label>
</template>

<script>
import optionMixin from "./mixins/optionMixin";
import checkIconMixin from "./mixins/checkIconMixin";
import isObject from "@/services/utils/isObject.js";
export default {
    mixins: [optionMixin, checkIconMixin],
    computed: {
        computedAttributes() {
            let attributes = {
                // set id using the ID computed property
                id: this.id,

                // set our checked status using the isChecked computed property
                checked: this.isChecked,

                // set our name using the name provided by our field schema
                name: this.field.name,

                // set our disabled status using the isDisabled computed mixin
                disabled: this.isDisabled,

                // set our classes using our computed classes mixin
                class: this.computedClasses,

                // set our model value from our modelValue prop
                value: this.modelValue,
            };

            // merge in any attributes from our field.attributes property
            if (isObject(this.field.attributes)) {
                attributes = { ...attributes, ...this.field.attributes };
            }

            return attributes;
        },

        isChecked() {
            return this.modelValue === this.option.value;
        },
    },

    methods: {
        handleInput() {
            this.$emit("updated", this.option.value);
            //this.updateValue(this.field.name, this.option.value);
        },
    },
};
</script>
